
import Calendariohome from '../components/shared-component/Fullcalendar/calendarhomesalf';

const Saltafila = () => {

  return (
    <>

      <main>
        <div className="container my-4">
          <div className="elementor-widget-container">
            <h2 style={{ fontWeight: 'bold', textAlign: 'center' }} className="elementor-heading-title elementor-size-default">Prenotazione Salta Fila Palatenda</h2>
          </div>


          <span className="elementor-divider-separator">
            <img className="separw"  style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }} src="/separatore.png"></img>
          </span>

          <br />
           <p style={{marginBottom: '1rem', marginTop:0, fontFamily:'Montserrat',textAlign: 'center', color:'black'}}>Sei ancora in fila? Acquista il tuo ticket che ti permetterà di accedere subito al Palatenda e avrai in omaggio un boccale di birra da un litro!</p> 
                                                                                                                      
           <p style={{marginBottom: '1rem', marginTop:0, fontFamily:'Montserrat',textAlign: 'center', color:'black'}}>Ingresso Biergarten Gratuito</p> 



        
          <Calendariohome />

        </div>
      </main>

      <a href="#" aria-hidden="true" data-attribute="back-to-top" className="back-to-top">
        <svg className="icon icon-light">
          <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-up"></use>
        </svg>
      </a>



      <div className="notification dismissable with-icon" role="alert" id="notification-esempi" aria-labelledby="notification-esempi-title">
        <h5 id="notification-esempi-title">
          <svg className="icon">
            <use href="/bootstrap-italia/dist/svg/sprite.svg#it-info-circle" xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-info-circle"></use></svg
          >Esempio di utilizzo
        </h5>
        <p>
          <a href="/bootstrap-italia/docs/esempi/">Torna alla pagina principale degli esempi</a>
        </p>
        <button type="button" className="btn notification-close">
          <svg className="icon">
            <use href="/bootstrap-italia/dist/svg/sprite.svg#it-close" xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-close"></use>
          </svg>
          <span className="sr-only">Chiudi notifica: Titolo notification</span>
        </button>
      </div>

    </>

  )
}

export default Saltafila;
