import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import moment from "moment";
export default function Dashboard() {
    const {http} = AuthUser();
    const [userdetail,setUserdetail] = useState('');

    useEffect(()=>{
        fetchUserDetail();
    },[]);

    const fetchUserDetail = () =>{
        http.post('/me').then((res)=>{
            setUserdetail(res.data);
        });
    }

    
    const downloadtavolivip = () => {

        var s = document.getElementById('dataprenotazione');
        
        http.get('/ExportExcellTavoliVip?data='+s.value)
        .then(data => {

          window.open('https://oktoberfestapi.guides4you.it/'+s.value+'-tavolivip.xls');

        });
}
    const downloadtavoli = () => {

        var s = document.getElementById('dataprenotazione');
        
        http.get('/ExportExcellTavoli?data='+s.value)
        .then(data => {

          window.open('https://oktoberfestapi.guides4you.it/'+s.value+'-tavoli.xls');

        });
}
const downloadcucina = () => {

    var s = document.getElementById('dataprenotazione');
    
    http.get('/ExportExcellCucina?data='+s.value)
    .then(data => {

      window.open('https://oktoberfestapi.guides4you.it/'+s.value+'-cucina.xls');

    });
}



const ExportIncassoStripe = () => {

    
    http.get('/ExportIncassoStripe')
    .then(data => {

      window.open('https://oktoberfestapi.guides4you.it/incassostripe.xls');

    });
}

    const downloadaccrediti = () => {

        var s = document.getElementById('dataprenotazione');
        
        http.get('/ExportExcellTest?data='+s.value)
        .then(data => {

          window.open('https://oktoberfestapi.guides4you.it/'+s.value+'-accrediti.xls');

        });
}

    function renderElement(){
        if(userdetail){
            return <div>
                 <div className='row'>
                <div className='col-sm'>
                    <div class="form-group">
                        Data  <br />
                        <input id="dataprenotazione" value={moment().format('YYYY-MM-DD')} disabled/>
                        
                         {/**  <select style={{ fontFamily: 'Montserrat', color: 'black' }}  defaultValue="" >
                            <option value="">
                                Scegli un'opzione
                            </option>
                            <option value="2022-09-16">16/09/2022</option>
                            <option value="2022-09-17">17/09/2022</option>
                            <option value="2022-09-18">18/09/2022</option>
                            <option value="2022-09-19">19/09/2022</option>
                            <option value="2022-09-20">20/09/2022</option>
                            <option value="2022-09-21">21/09/2022</option>
                            <option value="2022-09-22">22/09/2022</option>
                            <option value="2022-09-23">23/09/2022</option>
                            <option value="2022-09-24">24/09/2022</option>
                            <option value="2022-09-25">25/09/2022</option>
                            <option value="2022-09-26">26/09/2022</option>
                            <option value="2022-09-27">27/09/2022</option>
                            <option value="2022-09-28">28/09/2022</option>
                            <option value="2022-09-29">29/09/2022</option>
                            <option value="2022-09-30">30/09/2022</option>
                            <option value="2022-10-01">01/10/2022</option>
                            <option value="2022-10-02">02/10/2022</option>

                        </select>
                        */} 
                    </div>
                    <button onClick={() => downloadaccrediti()}> Download File Excel ACCREDITI</button> <br/><br/>
                    <button onClick={() => downloadtavoli()}> Download File Excel POSTI TAVOLO PLATEA</button> <br/><br/>
                    <button onClick={() => downloadtavolivip()}> Download File Excel POSTI TAVOLO VIP</button> <br/><br/>

                    <button onClick={() => downloadcucina()}> Download File Excel CUCINA</button> <br/><br/>
                    <button onClick={() => ExportIncassoStripe()}> Download INCASSO TOTALE Stripe</button> <br/><br/>
                   
                    
                    
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Export in Excel</h1>
            { renderElement() }
        </div>
    )
}