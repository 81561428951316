
import React, { useState, useEffect } from 'react';
import AuthUser from '../../AuthUser';
const inputRef = React.createRef();


const  Autocomplete =(props)=> {
  const http = AuthUser().http;
  
  const  [selected,setselected]= useState(null);
  const  [text,settext]= useState(props.valu);


  useEffect(()=> {
inputRef.current.autocomplete({
      source: async (request, response) => {
        const r = await http.get(props.url, { params: { term: request.term } });
        response(r.data);
      },
      select: (event, ui) => {
        if (props.onChange) {
         setselected( ui.item );
          props.onChange(ui.item);
        }
      },
      response: (event, ui) => {
      },
      minLength: 3
    });
  },[]);
 
    return <input style={props.style} ref={inputRef}
      className={`form-control ui-autocomplete-input ${props.size != undefined && props.size == 'sm' ? 'form-control-sm' : ''}`}
      placeholder={props.placeholder} defaultValue={text} />;
  
}
export default Autocomplete;
