import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export default function Gadget() {
    const navigate = useNavigate();
    const location = useLocation();
    const { http } = AuthUser();

    const [states, setstates] = useState(null);

    const [gadget, setgadget] = useState([]);

    const [totale, settotale] = useState(0);
   
    const modificaPostimeno = (evt) => {

        let gad = gadget;

        Object.entries(gad).forEach(

            ([key, value]) => {
                if (value.id == evt){
                    value.prenotati--;
                if (value.prenotati < 0){
                    value.prenotati = 0;
                }else{
                    let  tot = totale;

                    tot-=Math.floor(value.prezzovip)
  
                      settotale(tot);
                }
            }
        }

        );
        setgadget(gad);
    }
    const modificaPostipiu = (evt) => {

        let gad = gadget;

        Object.entries(gad).forEach(

            ([key, value]) => {
                if (value.id == evt){
                    value.prenotati++;

                  let  tot = totale;

                  tot+= Math.floor(value.prezzovip)

                    settotale(tot);
                //  console.log(key, value)
            }
        }
        );

        setgadget(gad);
    }


    useEffect(() => {
        (async () => {
            let result = await fetchProdottiDetail();
          //  console.log('location effect',location);
           // console.log('result efferct',result);
            setstates(location.state.state);
            
            setgadget(result.data);
            settotale(location.state.totale);
          
        })()

    }, []);

    const handleInputChange = (evt) => {

     
    }

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    const fetchProdottiDetail = async () => {

        const result = await http.post('getgadget', {  dataprenotazione: location.state.dataprenotazione });
        return result.data;

    }

    return (
        <div>
                <div className="elementor-widget-container">
                <h2 style={{ fontWeight: 'bold' , textAlign:'center'}} className="elementor-heading-title elementor-size-default">Vuoi aggiungere Gadget alla tua prenotazione?</h2>
            </div>
            <span className="elementor-divider-separator">
                            <img className="separw" style={{display: 'block', marginRight:'auto', marginLeft:'auto'}} src="/separatore.png"></img>
                        </span>
          
            <div>

               

              
                <div className='col-sm' style={{ padding: 25 }}>   <label style={{color:'black',fontWeight:'bold',fontSize:20}} >Prezzo Totale</label>
                            <label style={{color:'black',fontWeight:'bold',fontSize:20,marginLeft: 5}} >{totale + ' €'}</label>
                        </div>


            

                {gadget && gadget.map(p => (
                    <div key={'key'+p.id} className="row" style={{ border: 'solid 1px', margin: 5 }}>

                        <div style={{ float: 'left', maxWidth: 280, margin: 20 }} className="col-sm card-wrapper">
                            <div style={{ border: 0 }} className="card  no-after special-card" href="#">
                                <div className="img-responsive-wrapper">
                                    <div className="img-responsive">
                                        <figure className="img-wrapper"> <img
                                            src={'http://oktoberfestapi.guides4you.it/immagini/' + p.immagine}
                                            title="img title"
                                            alt="imagealt" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: 20 }} className='col-sm'>
                            <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} >{p.nome}</label>
                           
                            <br />
                        
                            <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} >Prezzo</label>
                            <label style={{ marginLeft: 5 }}>{parseFloat(p.prezzovip).toFixed(2) + ' €' }</label>
                        </div>
                        <div style={{ padding: 15 }} className='col-sm align-self-center'>
                            <div className="w-50 mt-5">
                                <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize:15 }} htmlFor={'posti-' + p.id} className="input-number-label">Numero </label>
                                <span className="input-number">
                                    <input type="number" id={'posti-' + p.id} value={p.prenotati} name={'posti-' + p.id} onChange={handleInputChange} />
                                    <button style={{ top: -14 }} onClick={() => modificaPostipiu(p.id)} className="input-number-add">
                                        <span className="sr-only">Aumenta valore</span>
                                    </button>
                                    <button  style={{ bottom: -14 }} onClick={() => modificaPostimeno(p.id)} className="input-number-sub">
                                        <span className="sr-only">Diminuisci valore</span>
                                    </button>
                                </span>
                            </div>

                        </div>

                    </div>

                ))
                }

             <div className="row">
                   

                    <div className="elementor-button-wrapper">
                                    <a style={{float:'right'}} className="elementor-button-link elementor-button elementor-size-sm"   onClick={() => totale > 0 && navigate('/paga', { state: {prenotazioneusata:location.state.prenotazioneusata, prenotazione:location.state.prenotazione, totale:totale, state:states ,gadget:gadget,tipo:location.state.tipo, fasciaoraria:location.state.fasciaoraria, dataprenotazione: moment(location.state.dataprenotazione).format('YYYY-MM-DD')} })}  role="button">
                                        <span className="elementor-button-content-wrapper">
                                            <span className="elementor-button-icon elementor-align-icon-right">
                                            </span>
                                            <span className="elementor-button-text">Avanti</span>
                                        </span>
                                    </a>
                                </div>

             </div>

            </div>
            <script>
            </script>
        </div>
    )
}