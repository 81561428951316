import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export enum Localization {
    it = "it",
    en = "en"
}

export enum ThemeColor{
    light = "LIGHT",
    dark = "DARK"
}

export type ThemeState = {
    language: string,
    theme: ThemeColor,
    popupSearch: boolean
   
    }

const initialState : ThemeState = {
    language: "it",
    theme: ThemeColor.light,
    popupSearch: false,

}


export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setThemeColor: (state, action: PayloadAction<ThemeColor>)=>{
            state.theme=action.payload
        },
        setThemeLocale: (state, action:PayloadAction<string>)=>{
            state.language = action.payload
        }
       



    }
});

export const {setThemeColor, setThemeLocale} = themeSlice.actions;
export const selectTheme = (state: RootState) => state.theme
export default themeSlice.reducer;
