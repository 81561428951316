
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import AuthUser from './components/AuthUser';
import { setIsLogged } from './features/user/userSlice';

import BOLayout from './layout/BoLayout';
import Views from './views';
function App() {

    const dispatch = useDispatch();

    useEffect (()=>{
        const result = AuthUser().getToken();
        
       if(result){
dispatch(setIsLogged(true));

       }else{
        dispatch(setIsLogged(false)); 
       }
    },[])

 return (
  <HashRouter>
  <Views/>
  </HashRouter>
 )

}

export default App;
