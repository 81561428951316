import React, { useEffect, useState, useId } from 'react';
import { Col, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import itLocale from '@fullcalendar/core/locales/it';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import AuthUser from '../../AuthUser';
import moment from "moment";
import shortid from 'shortid';

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

export default function AppCall() {

  const { http } = AuthUser();
  const [display, setDisplay] = useState({ vip: true, platea: true })
  const [events, setEvents] = useState([])
  const [calendarComponentRef, setcomponent] = useState({});
  const [states, setstates] = useState({});
  const [prodotti, setprodotti] = useState([]);
  const [gadget, setgadget] = useState([]);
  const [evento, setInsertEvento] = useState({

    prodotto: {},

    datainizio: null,
    datafine: null,
    orainiziogiorno: null,
    orafinegiorno: null,
    orafineprenoazioni: null,
    prezzovip: 0,
    postivip: 0,
    prezzoplatea: 0,
    postiplatea: 0

  });

  const handleInputChangeEvento = (evt) => {
    const s = evento;
    if (evt.target.name == 'prodotto') {
      console.log(evt);
      s[evt.target.name] = { id: evt.target.value, name: evt.target.options[evt.target.options.selectedIndex].innerHTML }
    } else {
      s[evt.target.name] = evt.target.value;
    }

    setInsertEvento(s);

  }
  const salvaGadget = () => {
    console.log(evento);

    let newEvents = new Array;
    
    let fine = moment(evento.datafine)

    let index = moment(evento.datainizio)
    while (index <= fine) {

        let m = shortid.generate();
        newEvents.push({
          idprodotto: evento.prodotto.id,
          title: evento.prodotto.name,
          start: new Date(index.format('YYYY-MM-DD') + " 01:00:00" ),
          end: new Date(index.format('YYYY-MM-DD') + " 23:59:59" ),
          postivip: evento.qtgadget,
          prezzovip: evento.prezzogadget,
          postiplatea: 0,
          prezzoplatea: 0,
          tipo: 'Gadget',
          fineprenotazioni: new Date(index.format('YYYY-MM-DD') + " 23:59:59" ),
          id: m
        });
      
      index.add(1, 'days');
    }

    let Events = [...events, ...newEvents];

    setEvents(Events)

    window.$('.bd-example-modal-lg2').modal('toggle');
   
  }

  const salvaEvento = () => {
    console.log(evento);

    let newEvents = new Array;
    let inizio = moment(evento.datainizio)
    let fine = moment(evento.datafine)

    let index = moment(evento.datainizio)

    while (index <= fine) {

      if (!display.vip) {
        let m = shortid.generate();
        newEvents.push({
          idprodotto: evento.prodotto.id,
          title: evento.prodotto.name,
          start: new Date(index.format('YYYY-MM-DD') + " " + evento.orainiziogiorno),
          end: new Date(index.format('YYYY-MM-DD') + " " + evento.orafinegiorno),
          postivip: evento.postivip,
          prezzovip: evento.prezzovip,
          postiplatea: 0,
          prezzoplatea: 0,
          tipo: 'vip',
          fineprenotazioni: new Date(index.format('YYYY-MM-DD') + " " + evento.orafineprenoazioni),
          id: m
        });
      }

      if (!display.platea) {
        let g = shortid.generate();
        newEvents.push({
          idprodotto: evento.prodotto.id,
          title: evento.prodotto.name,
          start: new Date(index.format('YYYY-MM-DD') + " " + evento.orainiziogiorno),
          end: new Date(index.format('YYYY-MM-DD') + " " + evento.orafinegiorno),
          postiplatea: evento.postiplatea,
          prezzoplatea: evento.prezzoplatea,
          postivip: 0,
          prezzovip: 0,
          tipo: 'platea',
          fineprenotazioni: new Date(index.format('YYYY-MM-DD') + " " + evento.orafineprenoazioni),
          id: g
        });
      }

      index.add(1, 'days');
    }

    let Events = [...events, ...newEvents];

    setEvents(Events)

    window.$('.bd-example-modal-lg').modal('toggle');
    console.log('eventi', Events);

  }

  const Toast = Alert.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
  })

  const salvacalendario = () => {

    // console.log(events);
    let e = calendarComponentRef.current.getApi().getEvents();
    let ev = new Array();

    e.forEach(element => {
      //   console.log(element);
      ev.push({
        idprodotto: element.extendedProps.idprodotto,
        title: element.title,
        start: element.start,
        end: element.end,
        postivip: element.extendedProps.postivip,
        prezzovip: element.extendedProps.prezzovip,
        postiplatea: element.extendedProps.postiplatea,
        prezzoplatea: element.extendedProps.prezzoplatea,
        fineprenotazioni: element.extendedProps.fineprenotazioni,
        id: element.id
      })
    });

    http.post('salvacalendario', { eventi: ev })
    .then((response) => {
      if (response.data.success) {
        Toast.fire({
          title: response.data.text,
          icon: 'success'
        });
      }
    }).catch((reason) => {

      Toast.fire({
        title: reason.response.data.message,
        icon: 'error'
      });
    });

  }

  const fetchEventiDetail = () => {

    http.get('/evento').then((res) => {

      let newEvents = new Array;

      res.data != null &&
        res.data.map((p) => {

          console.log(p);
          newEvents.push(
            {
              idprodotto: p.idprodotto,
              title: p.title,
              start: new Date(p.dataorainizio),
              end: new Date(p.dataorafine),
              postivip: p.postivip,
              postiplatea: p.postiplatea,
              prezzovip: p.prezzovip,
              prezzoplatea: p.prezzoplatea,
              fineprenotazioni: new Date(p.fineprenotazioni),
              id: p.id
            }
          )
        }
        );

      setEvents(newEvents);

    });
  }

  const fetchProdottiDetail = () => {

    http.get('/prodotti').then((res) => {

      let prodotti = new Array();
      let gadget = new Array();

      {
        res.data != null &&
          res.data.map((p) => {
            if (p.tipo != 'Gadget')
              prodotti.push({ id: p.id, title: p.nome })
            else
              gadget.push({ id: p.id, title: p.nome })
          }
          );
        setgadget(gadget);
        setprodotti(prodotti);
      }
    });
  }

  /**
   * adding dragable properties to external events through javascript
   */
  useEffect(() => {

    setcomponent(React.createRef());
    fetchProdottiDetail();

    fetchEventiDetail();

    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("data");
        return {
          title: title,
          id: id
        };
      }

    });

  }, []);


  /**
   * when we click on event we are displaying event details
   */
  const eventClick = eventC => {
    console.log(states);
    Alert.fire({
      title: eventC.event.title,
      html:
        `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr >
      <td>Title</td>
      <td><strong>` +
        eventC.event.title +
        `</strong></td>
      </tr>
      <tr >
      <td>Configura Data Inizio</td>
      <td><strong>
      </strong></td>
      </tr>
      <tr >
      <td>Configura Data Fine</td>
      <td><strong>
      </strong></td>
      </tr>
      <tr>
      <td>Numero Posti Disponibili</td>
      <td><strong>
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Remove Event",
      cancelButtonText: "Close"
    }).then(result => {
      if (result.value) {
        eventC.event.remove(); // It will remove event from the calendar
        Alert.fire("Deleted!", "Your Event has been deleted.", "success");
      }
    });
  };

  return (
    <div className="animated fadeIn p-4 demo-app">
      <Row>
        <Col lg={3} sm={3} md={3}>
          <div
            id="external-events"
            style={{
              padding: "10px",
              width: "80%",
              height: "auto",
              maxHeight: "-webkit-fill-available",
              backgroundColor: "white",
              border: "none"
            }}
          >
            <button
              type="button"
              className="btn btn-primary "
              data-toggle="modal"
              data-target=".bd-example-modal-lg">
              Crea Evento
            </button>
<br/><br/>
            <button
              type="button"
              className="btn btn-primary "
              data-toggle="modal"
              data-target=".bd-example-modal-lg2">
              Aggiungi Gadget
            </button>

     

            <button
              type="button"
              className="btn btn-primary "
              onClick={salvacalendario}
              style={{ marginTop: 60 }}
            >
              Salva Calendario
            </button>
          </div>
        </Col>

        <Col lg={9} sm={9} md={9}>
          <div className="demo-app-calendar" id="mycalendartest">
            <FullCalendar
              locale={itLocale}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
              }}
              rerenderDelay={10}
              eventDurationEditable={true}
              editable={true}
              droppable={true}
              ref={calendarComponentRef}
              weekends={states.calendarWeekends}
              events={events}
              eventDrop={states.drop}
              drop={states.drop}
              eventReceive={states.eventReceive}
              eventClick={eventClick}
              selectable={true}
            />
          </div>
        </Col>
      </Row>
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ padding: 30 }} >
            <h5
              className="modal-title"
              id="myLargeModalLabel" style={{ margin: 20 }}>Crea Evento </h5>
            <br />


            <div className="row">
              <div className="select-wrapper">
                <label for="defaultSelect">Prodotto Associato</label>
                <select id="prodotto" name="prodotto" defaultValue={evento.prodotto.id} onChange={handleInputChangeEvento}>
                  <option selected="" value="">Scegli un'opzione</option>

                  {prodotti.map(event => (
                    <option data={event.title} value={event.id}>{event.title}</option>))
                  }
                </select>
              </div>
            </div>

            <br />
            <br />
            <div className="row">
              <div className="col-sm">  <div className="form-group">
                <label className="active" for="datainizio">Data Inizio</label>
                <input
                  type="date"
                  id="datainizio"
                  name="datainizio"
                  defaultValue={evento.datainizio}
                  onChange={handleInputChangeEvento}
                />

              </div> </div>
              <div className="col-sm">  <div className="form-group">
                <label className="active" for="datafine">Data Fine</label>
                <input
                  type="date"
                  id="datafine"
                  name="datafine"
                  defaultValue={evento.datafine}
                  onChange={handleInputChangeEvento}
                />

              </div> </div>

            </div>
            <br />

            <div className="row">
              <div className="col-sm"> <div className="form-group">
                <label className="active" for="orainiziogiorno">Orario inizio Giorno</label>
                <input
                  className="form-control"
                  name="orainiziogiorno"
                  id="orainiziogiorno"
                  type="time"
                  defaultValue={evento.orainiziogiorno}
                  onChange={handleInputChangeEvento}
                />

              </div> </div>

              <div className="col-sm">
                <div className="form-group">
                  <label className="active" for="orafinegiorno">Orario Fine Giorno</label>
                  <input
                    className="form-control"
                    name="orafinegiorno"
                    id="orafinegiorno"
                    type="time"
                    defaultValue={evento.orafinegiorno}
                    onChange={handleInputChangeEvento}
                  />

                </div>
              </div>

            </div>

            <br />

            <div className="row">
              <div className="col-sm"> <div className="form-group">
                <label className="active" for="orafineprenoazioni">Orario Chiusura Prenotazioni</label>
                <input
                  className="form-control"
                  name="orafineprenoazioni"
                  id="orafineprenoazioni"
                  type="time"
                  defaultValue={evento.orafineprenoazioni}
                  onChange={handleInputChangeEvento}
                />

              </div> </div>

              <div className="col-sm">
                <div className="form-check form-check-inline">
                  <input
                    id="vip"
                    type="checkbox"
                    onChange={() => setDisplay({ vip: !display.vip, platea: display.platea })}
                  />
                  <label htmlFor="vip">
                    Vip
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input id="platea" type="checkbox"
                    onChange={() => setDisplay({ vip: display.vip, platea: !display.platea })}
                  />
                  <label htmlFor="platea">
                    Platea
                  </label>
                </div>

              </div>
              <div style={{ display: display.vip ? 'none' : 'flex' }} className="row">
                <div className="col-sm">

                  <div className="form-group">
                    <label for="postivip">Posti Vip</label>
                    <input type="number" id="postivip" name="postivip"
                      defaultValue={evento.postivip}
                      onChange={handleInputChangeEvento}
                    />

                  </div>

                </div>
                <div className="col-sm">

                  <div className="form-group">
                    <label for="prezzovip">Prezzo Vip</label>
                    <input type="number" id="prezzovip" name="prezzovip"
                      defaultValue={evento.prezzovip}
                      onChange={handleInputChangeEvento}
                    />

                  </div>

                </div>
              </div>

              <div style={{ display: display.platea ? 'none' : 'flex' }} className="row">
                <div className="col-sm">

                  <div className="form-group">
                    <label for="postiplatea">Posti Platea</label>
                    <input type="number" id="postiplatea" name="postiplatea"
                      defaultValue={evento.postiplatea}
                      onChange={handleInputChangeEvento}
                    />

                  </div>

                </div>
                <div className="col-sm">

                  <div className="form-group">
                    <label for="prezzoplatea">Prezzo Platea</label>
                    <input type="number" id="prezzoplatea" name="prezzoplatea"
                      defaultValue={evento.prezzoplatea}
                      onChange={handleInputChangeEvento}
                    />

                  </div>

                </div>

              </div>
            </div>

            <button
              onClick={salvaEvento}
              type="button"
              className="btn btn-primary "
              data-target=".bd-example-modal-lg">
              Salva
            </button>
          </div>

        </div>
      </div>


      <div
        className="modal fade bd-example-modal-lg2"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ padding: 30 }} >
            <h5
              className="modal-title"
              id="myLargeModalLabel2" style={{ margin: 20 }}>Aggiungi Gadget </h5>
            <br />


            <div className="row">
              <div className="select-wrapper">
                <label for="defaultSelect">Prodotto Associato</label>
                <select id="prodotto" name="prodotto" defaultValue={evento.prodotto.id} onChange={handleInputChangeEvento}>
                  <option selected="" value="">Scegli un'opzione</option>

                  {gadget.map(g => (
                    <option data={g.title} value={g.id}>{g.title}</option>))
                  }
                </select>
              </div>
            </div>

            <br />
            <br />
            <div className="row">
              <div className="col-sm">  <div className="form-group">
                <label className="active" for="datainizio">Data Inizio</label>
                <input
                  type="date"
                  id="datainizio"
                  name="datainizio"
                  defaultValue={evento.datainizio}
                  onChange={handleInputChangeEvento}
                />

              </div> </div>
              <div className="col-sm">  <div className="form-group">
                <label className="active" for="datafine">Data Fine</label>
                <input
                  type="date"
                  id="datafine"
                  name="datafine"
                  defaultValue={evento.datafine}
                  onChange={handleInputChangeEvento}
                />

              </div> </div>

            </div>
   
   
              <div className="row">
              <div className="col-sm">
                <div className="form-group">
                  <label for="prezzogadget">Prezzo Gadget</label>
                  <input type="number" id="prezzogadget" name="prezzogadget"
                    defaultValue={evento.prezzogadget}
                    onChange={handleInputChangeEvento}
                  />

                </div>
                </div>
                <div className="col-sm">
                <div className="form-group">
                  <label for="qtgadget">Disponibilità Giornaliera</label>
                  <input type="number" id="qtgadget" name="qtgadget"
                    defaultValue={evento.qtgadget}
                    onChange={handleInputChangeEvento}
                  />

                </div>
                </div>

              </div>

              <br />

              <button
                onClick={salvaGadget}
                type="button"
                className="btn btn-primary "
                data-target=".bd-example-modal-lg2">
                Salva
              </button>
            </div>

          </div>
        </div>


      </div>
      );

}

//const rootElement = document.getElementById("root");

//ReactDOM.render(<AppCall />, rootElement);


