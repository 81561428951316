import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import Stripe from './shared-component/Stripe/App'
import { useDispatch } from 'react-redux';
import { selectUser, setPagamento } from '../features/user/userSlice';
import { useAppSelector } from '../app/hooks';

export default function Paga() {


    const user = useAppSelector(selectUser);
    const location = useLocation();
    const { http } = AuthUser();
    const dispatch = useDispatch();

    const [pagare, setpaga] = useState(false);

    const [bigliettospecial, setbigliettospecial] = useState(null);

    const [verificadati, setverificadati] = useState(false);

    const [verificacondizioni, setverificacondizioni] = useState(false);
    const [posti, setposti] = useState(0);

    const [states, setstates] = useState(location.state.state);

    const [datiprenotazione, setdatiprenotazione] = useState({
        success: false,
        coupon: null,
        textcoupon: '',
        prenotazione: null,
        textprenotazione: null,
        prezzototale: 0

    });

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pagare]);

    useEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            let result = await fetchVerifica();
            //    console.log('useeffectresult', location.state);
            //    console.log('useeffect',datiprenotazione);
            let stat = {
                ...location.state, prezzoscontato: result.prezzoscontato,
                couponusato: result.couponverificato
                , prenotazioneusata: result.prenotazioneverificata,
                paga: false

            }
            console.log('useeffectresult', location.state);
            let posti = 0;
            location.state.state.forEach(element => {
                posti += element.prenotati;
            });

            setposti(posti);
            setstates(stat);
            setdatiprenotazione(result);
            dispatch(setPagamento(location.state));
        })() //*/

    }, []);

    const fetchpaga = async () => {

       
        const result = await http.post('prenotaspecial', {
            prenotazione: states,
            
        });

       setbigliettospecial(result.data.success);

        return result.data;
    }

    const fetchVerifica = async () => {

        let prezzototale = location.state.totale;
        const result = await http.post('getcouponeprenota', {
            prenotazione: location.state.prenotazioneusata,
            coupon: location.state.coupon, prezzototale: prezzototale
        });
        return result.data;
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        //   alert("You are writing invalid email address!")
        return (false)
    }

    const paga = (p) => {
        //  console.log('paga', states);

        if (verificadati) {
            setpaga(p);
            window.scrollTo(0, 0);
        };
    }

    const handleInputChange = (evt) => {

        const s = states;
        s[evt.target.name] = evt.target.value;

        setstates(s);

        if (evt.target.type == 'checkbox') {
            if (document.querySelectorAll('input[name=ceck]:checked').length == 5) {
                setverificacondizioni(true);
            }

        }

        if ((evt.target.name == 'comune' && states.cambio_email != states.conferma_email)) {

            Toast.fire({
                title: 'Email non conforme o Conferma Email non coincidono',
                icon: 'error'
            });

            setverificadati(false);
        }

        if (states.cambio_nome != undefined && states.cambio_cognome != undefined &&
            states.cambio_email === states.conferma_email &&
            states.cambio_telefono != undefined &&
            states.cap != undefined &&
            states.comune != undefined &&
            validateEmail(states.cambio_email) &&
            states.cambio_email != undefined
        ) { setverificadati(true) } else {
            setverificadati(false)
            // console.log('verifica False', states);
        };

    }

    return (
        <div >

            <div className="elementor-widget-container">
                <h2 style={{ fontWeight: 'bold', textAlign: 'center' }} className="elementor-heading-title elementor-size-default">Paga</h2>
            </div>
            <span className="elementor-divider-separator">
                <img className="separw" style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }} src="/separatore.png"></img>
            </span>
            {
                datiprenotazione.success && datiprenotazione.coupon != null && (
                    <div>
                        {datiprenotazione.textcoupon}
                    </div>)

            }
            {
                datiprenotazione.success && datiprenotazione.prenotazione != null && (
                    <div>
                        {datiprenotazione.textprenotazione}
                    </div>)

            }

            {/*  <dl className="row">
                <dt className="col-sm-4">Prenotazione  </dt>
                <dd className="col-sm-5">{location.state.state.title}</dd>
            </dl>
            */}
            <dl className="row">
                <dt style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} className="col-sm-4">Prenotazione per il </dt>
                <dd style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} className="col-sm-5"> {moment(location.state.dataprenotazione).format('DD-MM-YYYY')}</dd>
            </dl>

            <dl className="row">
                <dt style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} className="col-sm-4">Prezzo </dt>
                <dd style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20, float: 'left', width: 100, textDecoration: datiprenotazione.prezzoscontato ? 'line-through' : 'auto' }} className="col-sm-5">{datiprenotazione.success && (location.state.totale + ' €')}
                </dd>
                <dd className="col-sm-5">{datiprenotazione.prezzoscontato && (datiprenotazione.prezzoscontato + ' €')}
                </dd>
            </dl>

            {!pagare && <div>
                <div className='row'>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_nome">Nome*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="cambio_nome"
                                id="cambio_nome" onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_cognome">Cognome*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="cambio_cognome"
                                id="cambio_cognome" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>

                <div className='row'>

                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_email">Email*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="cambio_email"
                                id="cambio_email" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="conferma_email">Conferma Email*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="conferma_email"
                                id="conferma_email" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_telefono">Cellulare*</label>
                            <input
                                type="number"
                                className="form-control"
                                name="cambio_telefono"
                                id="cambio_telefono" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cap">Cap*</label>
                            <input
                                type="number"
                                className="form-control"
                                name="cap"
                                id="cap" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="comune">Comune*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="comune"
                                id="comune" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='col-sm'>
                        <div className="form-group">

                        </div>
                    </div>
                </div>
            </div>}
            {!user.isLogged && !pagare && verificadati && !verificacondizioni && <div className="row">
                <div style={{ padding: 15 }} className='col-sm align-self-center'>
                    <h4>PRIMA DI PROCEDERE CON IL PAGAMENTO, CONFERMA PER PRESA VISIONE</h4>
                    <div class="col-10">
                        <div className="form-check form-check-group">
                            <input id="checkbox1" name='ceck' type="checkbox" onChange={handleInputChange} />

                            <label htmlFor="checkbox1">
                                Stai per confermare la prenotazione per n. {posti} persone, per il giorno {moment(location.state.dataprenotazione).format('DD-MM-YYYY')}, con ingresso alle ore {moment(location.state.dataprenotazione + ' ' + location.state.fasciaoraria).format('HH:mm')}
                            </label>
                        </div>
                        <div className="form-check form-check-group">
                            <input id="checkbox2" name='ceck' type="checkbox" onChange={handleInputChange} />

                            <label htmlFor="checkbox2">
                                Il documento che verrà generato è l’unico che certifichi l’avvenuta prenotazione e che ti consentirà di accedere all’Oktoberfest Calabria 2022 entrando dall’ingresso riservato ai prenotati
                            </label>
                        </div>
                        <div className="form-check form-check-group">
                            <input id="checkbox3" name='ceck' type="checkbox" onChange={handleInputChange} />
                            <label htmlFor="checkbox3">
                                Una copia potrà essere consegnata solo ed esclusivamente agli altri componenti interessati a questa prenotazione, fino al raggiungimento di numero {posti} accessi totali. Eventuali ulteriori tentativi di accesso con il medesimo QR code saranno respinti.
                            </label>
                        </div>
                        <div className="form-check form-check-group">
                            <input id="checkbox4" name='ceck' type="checkbox" onChange={handleInputChange} />
                            <label htmlFor="checkbox4">
                                È prevista una tolleranza di massimo 20 minuti dalle ore {moment(location.state.dataprenotazione + ' ' + location.state.fasciaoraria).format('HH:mm')} (un ritardo oltre l’orario limite comporterà la perdita del diritto alla presente prenotazione. Rimane la possibilità di recuperare tale diritto nelle serate successive – previa disponibilità dei posti – contattando l’organizzazione al numero telefonico +39 320 8046171 )
                            </label>
                        </div>
                        <div className="form-check form-check-group">
                            <input id="checkbox5" name='ceck' type="checkbox" onChange={handleInputChange} />
                            <label htmlFor="checkbox5">Sì, ho preso visione e accetto la <a href="https://www.oktoberfestcalabria.com/dichiarazione-sulla-privacy-ue/">Privacy Policy</a></label>
                        </div>
                    </div>
                </div>
            </div>

            }

            {
               !pagare && verificadati && verificacondizioni &&

                <div className="row">
                    <div style={{ padding: 15 }} className='col-sm align-self-center'>
                        <div className="elementor-button-wrapper">
                            <a className="elementor-button-link elementor-button elementor-size-sm" onClick={() => paga(true)} role="button">
                                <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-icon elementor-align-icon-right">
                                    </span>
                                    <span className="elementor-button-text">Effettua pagamento</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            }

            {!pagare && verificadati && user.isLogged &&  !bigliettospecial &&
            <div className="row">
                <div style={{ padding: 15 }} className='col-sm align-self-center'>

                    <div className="elementor-button-wrapper">
                        <a className="elementor-button-link elementor-button elementor-size-sm" onClick={() => fetchpaga()} role="button">
                            <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-icon elementor-align-icon-right">
                                </span>
                                <span className="elementor-button-text">Conferma Prenotazione</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            }

            {
                bigliettospecial && 
              <div>
                
                <a href={'https://oktoberfestapi.guides4you.it/api/scaricaBiglietto?i='+bigliettospecial} className='bnt'>Scarica biglietto</a>
              <br/>
              <br/>
              <a href={'/#/dashboard'} className='bnt'>Nuova Prenotazione</a>
           
              </div>    }


            <div>
                <div className="row">
                    {pagare &&
                        (
                            <Stripe prodotto={states} />)}
                </div>
            </div>
            <script>
            </script>
        </div >
    )
}