import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";
import AuthUser from '../../AuthUser';
import Swal from 'sweetalert2';
const Toast = Swal.mixin({
  position: 'top-end',
  timer: 2000,
  showConfirmButton: false,
  toast: true,
})
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const StripePayment = ({ prodotto }) => {

  const stripePromise = loadStripe("pk_live_51LSjNTCrkYDFPNq2jsR1B8gdx2RLQIxqXuOP4tzqI2LXL7MLMrdaQ0qK27c29XWvMbIFIkn4CNddkbsVMlxlp0KA00nnFHZarh");

  const [clientSecret, setClientSecret] = useState("");
  const { http } = AuthUser();
  useEffect(() => {

    console.log('Prodotto Stripe',prodotto);

    fetch("https://oktoberfestapi.guides4you.it/api/stripeCheckout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ acquisto:prodotto}),
    })
    .then((res) => res.json())
    .then((data) => setClientSecret(data.clientSecret));

  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

export default StripePayment;