import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import Stripe from './shared-component/Stripe/App'
import { useDispatch } from 'react-redux';
import { selectUser, setPagamento } from '../features/user/userSlice';
import { useAppSelector } from '../app/hooks';

export default function Paga() {


    const user = useAppSelector(selectUser);
    const location = useLocation();
    const { http } = AuthUser();
    const dispatch = useDispatch();

    const [pagare, setpaga] = useState(false);

    const [verificadati, setverificadati] = useState(false);

    const [states, setstates] = useState(location.state);

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    useEffect(() => {
    }, [pagare]);

    useEffect(() => {

        const s = states;
        s['prenotazioneusata'] = '';
        s['gadget'] = [];
        s['state'] = [{
            'prenotati':location.state.prenotati, 
            'id':'salta', 
            'prezzovip':location.state.totale/location.state.prenotati, 
            'prezzoplatea':0, 
            'idprodotto':'50', 
        }
    ];
        setstates(s);

        console.log('states',states);
    
    }, []);

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        //   alert("You are writing invalid email address!")
        return (false)
    }

    const paga = (p) => {
        //  console.log('paga', states);

        if (verificadati) {
            setpaga(p);
            window.scrollTo(0, 0);
        };
    }

    const handleInputChange = (evt) => {

        const s = states;
        s[evt.target.name] = evt.target.value;

        setstates(s);

        if ((evt.target.name == 'comune' && states.cambio_email != states.conferma_email)) {

            Toast.fire({
                title: 'Email non conforme o Conferma Email non coincidono',
                icon: 'error'
            });

            setverificadati(false);
        }

        if (states.cambio_nome != undefined && states.cambio_cognome != undefined &&
            states.cambio_email === states.conferma_email &&
            states.cambio_telefono != undefined &&
            states.cap != undefined &&
            states.comune != undefined &&
            validateEmail(states.cambio_email) &&
            states.cambio_email != undefined
        ) { setverificadati(true) } else {
            setverificadati(false)
            // console.log('verifica False', states);
        };

    }

    return (
        <div >

            <div className="elementor-widget-container">
                <h2 style={{ fontWeight: 'bold', textAlign: 'center' }} className="elementor-heading-title elementor-size-default">Paga</h2>
            </div>
            <span className="elementor-divider-separator">
                <img className="separw" style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }} src="/separatore.png"></img>
            </span>
         

            {/*  <dl className="row">
                <dt className="col-sm-4">Prenotazione  </dt>
                <dd className="col-sm-5">{location.state.state.title}</dd>
            </dl>
            */}
            <dl className="row">
                <dt style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} className="col-sm-4"> Salta Fila per il </dt>
                <dd style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} className="col-sm-5"> {moment(location.state.dataprenotazione).format('DD-MM-YYYY')}</dd>
            </dl>

            <dl className="row">
                <dt style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} className="col-sm-4">Prezzo </dt>
                <dd style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20, float: 'left', width: 100, textDecoration:   'auto' }} className="col-sm-5">{ (location.state.totale + ' €')}
                </dd>
               
                
            </dl>

            {!pagare && <div>
                <div className='row'>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_nome">Nome*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="cambio_nome"
                                id="cambio_nome" onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_cognome">Cognome*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="cambio_cognome"
                                id="cambio_cognome" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>

                <div className='row'>

                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_email">Email*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="cambio_email"
                                id="cambio_email" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="conferma_email">Conferma Email*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="conferma_email"
                                id="conferma_email" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cambio_telefono">Cellulare*</label>
                            <input
                                type="number"
                                className="form-control"
                                name="cambio_telefono"
                                id="cambio_telefono" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="cap">Cap*</label>
                            <input
                                type="number"
                                className="form-control"
                                name="cap"
                                id="cap" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm'>
                        <div className="form-group">
                            <label htmlFor="comune">Comune*</label>
                            <input
                                type="text"
                                className="form-control"
                                name="comune"
                                id="comune" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='col-sm'>
                        <div className="form-group">

                        </div>
                    </div>
                </div>
            </div>}
          

            {
               !pagare && verificadati  &&

                <div className="row">
                    <div style={{ padding: 15 }} className='col-sm align-self-center'>
                        <div className="elementor-button-wrapper">
                            <a className="elementor-button-link elementor-button elementor-size-sm" onClick={() => paga(true)} role="button">
                                <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-icon elementor-align-icon-right">
                                    </span>
                                    <span className="elementor-button-text">Effettua pagamento</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            }


            <div>
                <div className="row">
                    {pagare &&
                        ( <Stripe prodotto={states} />) }
                </div>
            </div>
            <script>
            </script>
        </div >
    )
}