import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';


export default function Prenota() {

    const navigate = useNavigate();
    const location = useLocation();
   
    const { http } = AuthUser();
    const [associata, setassociata] = useState(false);
    const [fasciaorarioa, setfascia] = useState('');
    const [states, setstates] = useState([]);
    const [iamico, setamico] = useState(null);
    const [tipo, settipo] = useState('');
    const [opacy, setopacy] = useState([0.5, 0.5]);
    const [hasgadget, sethasgadget] = useState(false);
    const [prenotazioneusata, setprenotazioneusata] = useState(null);
    const [prenotazione, setprenotazione] = useState(null);

    const [totale, settotale] = useState(0);
    useEffect(() => {
        //  console.log(states);
        //  fetchProdottiDetail();
    //    window.scrollTo(0, 0);
    }, [totale]);


    const reset = (evt) => {
        setassociata(false);
        setfascia('');
        setstates([]);
        settipo('');
        setopacy([0.5, 0.5]);
        setprenotazioneusata(null);
        setprenotazione(null)
        settotale(0);

    }
    const verificapre = (evt) => {

        http.post('verificaprenotazione',
            {
                dataprenotazione: moment(location.state.dataprenotazione).format('YYYY-MM-DD'),
                codiceprenotazione: prenotazione
            }
        ).then((response) => {

            //   console.log(response.data.success);

            if (response.data.success === true) {
                settipo(response.data.tipo);
                setfascia(moment(response.data.dataoraprenotazione).format('HH:mm'));
                fetchProdottiDetail(response.data.tipo);
                setprenotazioneusata(prenotazione);
                setassociata(true);

                Toast.fire({
                    title: 'Prenotazione Associata Correttamente',
                    icon: 'success'
                });

            } else {
                Toast.fire({
                    title: response.data.text,
                    icon: 'error'
                });
            }

        }).catch((reason) => {

            Toast.fire({
                title: reason.response.data.message,
                icon: 'error'
            });
        })

    }

    const modificaPostimeno = (evt) => {

        let stat = states;

        Object.entries(stat).forEach(

            ([key, value]) => {
               
                if (value.id == evt) {
                  
                    value.prenotati= value.prenotati - value.prodotti.posti;
                    if (value.prenotati < 0) {
                        value.prenotati = 0;
                    } else {
                        let tot = totale;
                        tot -= Math.floor((value.prezzoplatea > 0 ? value.prezzoplatea : value.prezzovip))
                        settotale(tot);
                    }

                }
            }
        );
        setstates(stat);
    }
    const modificaPostipiu = (evt) => {

        let stat = states;

        Object.entries(stat).forEach(
           
            ([key, value]) => {
               
                if (value.id == evt) {
                    console.log('postimeno',value.prodotti.posti);
                    value.prenotati= value.prenotati + value.prodotti.posti;

                    let tot = totale;

                    tot += Math.floor(value.prezzoplatea > 0 ? value.prezzoplatea : value.prezzovip)

                    settotale(tot);
                }
                //  console.log(key, value)
            }
        );

        setstates(stat);
    }

    const handleInputChangepre = (evt) => {
        //  console.log(evt);
        setprenotazione(evt.target.value);

    }
    const handleKeyPress = (evt) => {
        //  console.log(evt);
    }

    const handleInputChange = (evt) => {

    }
    const handleInputChangefascia = (evt) => {
        setfascia(evt.target.value);

    }

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    const fetchProdottiDetail = (tipo, associata) => {

        if(tipo=='Vip' && moment(location.state.dataprenotazione).format('YYYY-MM-DD')=='2022-10-01'){
            Toast.fire({
                title: 'Posti Vip Terminati',
                icon: 'error'
            });
        } else{

        if (associata) {

            Toast.fire({
                title: 'Non puoi cambiare la posizione associata alla prenotazione',
                icon: 'error'
            });

        } else {

            http.post('geteventi', { posti: tipo, dataprenotazione: moment(location.state.dataprenotazione).format('YYYY-MM-DD') })
                .then((response) => {

                    if (response.data) {
                        setstates(response.data);
                        sethasgadget(response.data.filter(f =>  f.prodotti.tipo=='Gadget').lenght>0);
                        settotale(0);
                        settipo(tipo);

                        if (tipo == 'Platea') {
                            setopacy([1, 0.5]);
                        } else {
                            setopacy([0.5, 1]);
                        }

                        //     console.log('set tipo', tipo);
                    }
                }).catch((reason) => {

                    Toast.fire({
                        title: reason.response.data.message,
                        icon: 'error'
                    });
                });
        }}
    }
         {/* onWheel={ event => {
            if (event.nativeEvent.wheelDelta > 0) {
            //  console.log('scroll up '+event.nativeEvent.pageY);
              setscrolltop(0)
            } else {
             // console.log('scroll down '+event.nativeEvent.pageY);
              setscrolltop(0)
            }
          }}*/ }

    return (
        <div
   
         >
            <div  className="elementor-widget-container">
                <h2 style={{ fontWeight: 'bold', textAlign: 'center' }} className="elementor-heading-title elementor-size-default">Prenotazione per giorno {moment(location.state.dataprenotazione).format('DD-MM-YYYY')}</h2>
            </div>
            <span className="elementor-divider-separator">
                <img className="separw"  style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }} src="/separatore.png"></img>
            </span>

            <div>
                { /*<div className="row">
                    <div className="form-group">
                        <label htmlFor="posti" className="input-number-label">Numero di Posti Da Prenotare</label>
                       
                            <input type="number" id="posti"  aria-describedby="postidesc" name="posti" defaultValue={postiprenotati.posti} onChange={modificaPosti} />
                            <small id="postidesc" className="form-text text-muted">Numero di posti al tavolo</small>     
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <label htmlFor="coupon" className="input-number-label">Coupon</label>
                      
                            <input type="text" id="coupon"  aria-describedby="coupondesc" name="coupon" onChange={modificaPosti} />

                            <small id="coupondesc" className="form-text text-muted">Inserisci un coupon sconto</small>
                    </div>
                </div>
                <div className="row">
                <div className="form-group">
                        <label htmlFor="prenotazione" className="input-number-label">Associa Prenotazione</label>
                       
                            <input type="text" id="prenotazione" aria-describedby="prenotdesc"  name="prenotazione" onChange={modificaPosti} />

                            <small id="prenotdesc" className="form-text text-muted">Associa la prenotazione di un'amico per stare assieme al tavolo</small>                    
                    </div>
                </div>

                <br/>
    */}

                <div className="row">
                    <h1 className='mb-4 mt-4'>devi aggiungere la prenotazione ad una già esistente?  <img
                        src="/information.png"
                        title="se devi unirti ad una prenotazione già effettuata, chiedi il numero già assegnato a quella fatta in precedenza dai tuoi amici"
                        width={25}
                        alt="se devi unirti ad una prenotazione già effettuata, chiedi il numero già assegnato a quella fatta in precedenza dai tuoi amici" /> </h1>

                    <div style={{ marginBottom: 15 }}>
                        <div className=" form-check-inline">
                            <input onClick={() => setamico(false)} onChange={reset} name="amico" type="radio" id="amicono" />
                        </div>
                        <div className=" form-check-inline">   <label style={{ color: 'black' }} htmlFor="amicono">No</label></div>

                        <div className=" form-check-inline">
                            <input onClick={() => setamico(true)} onChange={reset} name="amico" type="radio" id="amicosi" />

                        </div>
                        <div className=" form-check-inline">   <label style={{ color: 'black' }} htmlFor="amicosi">Si</label></div>

                    </div>


                </div>
                {iamico &&
                    <div style={{ padding: 0 }} className="row">
                        <div className="form-group">
                            <div className="input-group">

                                <label className="acapo" htmlFor="prenotazione">Scrivi codice il codice biglietto da associare</label>
                                <input style={{ maxWidth: 500, marginRight: 30 }}
                                    type="text"
                                    className="form-control acapot"
                                    id="prenotazione"
                                    name="prenotazione" onChange={handleInputChangepre} />


                                <div className="elementor-button-wrapper">
                                    <a className="elementor-button-link elementor-button elementor-size-sm" id="verificapre" onClick={() => verificapre()} role="button">
                                        <span className="elementor-button-content-wrapper">
                                            <span className="elementor-button-icon elementor-align-icon-right">
                                            </span>
                                            <span className="elementor-button-text">Verifica codice</span>
                                        </span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                }


                {associata && <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} > Fascia Oraria {fasciaorarioa}</label>}

                {iamico != null && !iamico && <div style={{ paddingLeft: 0, paddingRight: 30, paddingTop: 30, }} className="row">
                    <div className="form-group">
                        <div className="select-wrapper">
                            <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor="fasciaoraria">A QUALE ORA PREFERISCI ENTRARE?</label>
                            <br />
                            <p style={{marginBottom: '1rem', marginTop:0, fontFamily:'Montserrat', color:'black'}}>(Per le Domeniche a pranzo non è necessaria la prenotazione) </p> 
                            <br />
                            <select style={{ fontFamily: 'Montserrat', color: 'black' }} id="fasciaoraria" defaultValue="" onChange={handleInputChangefascia}>
                                <option value="">
                                    Scegli un'opzione
                                </option>
                                <option value="19:30">19:30</option>
                                <option value="20:00">20:00</option>
                                <option value="20:30">20:30</option>
                                <option value="21:00">21:00</option>
                                <option value="21:30">21:30</option>
                            </select>

                        </div>
                    </div>
                </div>}

                {fasciaorarioa != '' && <div>


                    <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }}>Scegli Posizione</label>

                    <div style={{ paddingLeft: 0, maxWidth: 990 }} className='row' >
                        <table style={{border:'none'}}>
                      {/* <tr>
                                <td>
                                </td>
                                <td>
                                    <img src='/palco.jpeg' style={{ width: '100%', height: 'auto', maxWidth: 290, height: 100 }} >
                                    </img></td>
                                    <td>
                                </td>
                            </tr> 
                            */} 
                            <tr>
                                <td><img onClick={() => fetchProdottiDetail('Platea', associata)} src='/plateab.jpeg' style={{ cursor: 'pointer', maxWidth: 290, width: '100%', height: 'auto', opacity: opacy[0] }} >
                                </img></td>
                                <td><img onClick={() => fetchProdottiDetail('Vip', associata)} src='/vip.jpeg' style={{ cursor: 'pointer', maxWidth: 290, width: '100%', height: 'auto', opacity: opacy[1] }} >
                                </img></td>
                                <td> <img onClick={() => fetchProdottiDetail('Platea', associata)} src='/plateaa.jpeg' style={{ cursor: 'pointer', maxWidth: 290, width: '100%', height: 'auto', opacity: opacy[0] }} >
                                </img></td>
                            </tr>
                        </table>


                        {/*   <div style={{ padding: 20 }} className='col-sm'>
                            <div className="form-group">
                                <button type="button" className="btn  btn-primary" onClick={() => fetchProdottiDetail('Vip')} >Area Vip</button>
                            </div>
                        </div>
                        <div style={{ padding: 20 }} className='col-sm'>
                            <div className="form-group">
                                <button type="button" className="btn  btn-primary" onClick={() => fetchProdottiDetail('Platea')} >Area Platea</button>
                            </div>
                        
                        </div>//*/}
                    </div>
                    <br />
                    {states.filter(f => f.prodotti.tipo!='Gadget').map(p => (
                        <div key={'key' + p.id} className="row prod" >

                            <div style={{ float: 'left', maxWidth: 280, margin: 20 }} className="col-sm card-wrapper">
                                <div style={{ border: 0 }} className="card  no-after special-card" href="#">
                                    <div className="img-responsive-wrapper">
                                        <div className="img-responsive">
                                            <figure className="img-wrapper"> <img
                                                src={'http://oktoberfestapi.guides4you.it/immagini/' + p.prodotti.immagine}
                                                title="img title"
                                                alt="imagealt" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ padding: 20 }} className='col-sm'>
                                <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }}>{p.prodotti.nome}</label>
                                <br />
                                {
                                    p.prodotti.descrizione.split("\n").map(p => (
                                        <p style={{ margin: 0 }}>{p}</p>
                                    ))
                                }
                                <br />
                                <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }}>{p.postiplatea > 0 ? 'Tavolo in PLATEA' : 'Tavolo in zona VIP'}</label>
                                <br />
                                <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} >Prezzo</label>
                                <label style={{ marginLeft: 5 }}>{parseFloat(p.prezzoplatea > 0 ? p.prezzoplatea : p.prezzovip).toFixed(2) + ' €'}</label>
                            </div>

                            <div style={{ padding: 15 }} className='col-sm align-self-center'>
                                <div className="mobs50 w-50 mt-5">
                                    <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor={'posti-' + p.prodotti.id} className="input-number-label">Numero </label>
                                    <span className="input-number">
                                        <input type="number" id={'posti-' + p.prodotti.id} value={p.prenotati} name={'posti-' + p.prodotti.id} onChange={handleInputChange} data-bs-input step="any" onKeyPress={handleKeyPress} />
                                        <button style={{ top: -14 }} onClick={() => modificaPostipiu(p.id)} className="input-number-add">
                                            <span className="sr-only">Aumenta valore</span>
                                        </button>
                                        <button style={{ bottom: -14 }} onClick={() => modificaPostimeno(p.id)} className="input-number-sub">
                                            <span className="sr-only">Diminuisci valore</span>
                                        </button>
                                    </span>
                                </div>

                            </div>

                        </div>
                    ))
                    }
                    <div className='separt'> </div>

                    {totale > 0 && <div className="row total">

                        <div  className='totprex'>   <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} >Prezzo Totale</label>
                            <label style={{ color: 'black', fontWeight: 'bold', fontSize: 20, marginLeft: 5 }} >{totale + ' €'}</label>
                        </div>

                        <div  className='prex col-sm align-self-center'>

                            <div className="elementor-button-wrapper">
                                <a className="elementor-button-link elementor-button elementor-size-sm"  onClick={() => totale > 0 && navigate( hasgadget?'/gadget':'/paga', { state: { gadget:[], prenotazioneusata: prenotazioneusata, prenotazione: prenotazione, totale: totale, state: states, tipo: tipo, fasciaoraria: fasciaorarioa, dataprenotazione: moment(location.state.dataprenotazione).format('YYYY-MM-DD') } })} role="button">
                                    <span className="elementor-button-content-wrapper">
                                        <span className="elementor-button-icon elementor-align-icon-right">
                                        </span>
                                        <span className="elementor-button-text">Avanti</span>
                                    </span>
                                </a>
                            </div>

                        </div>
                    </div>
                    }
                </div>}
            </div>
            <script>
            </script>
        </div>
    )
}