import React, { useEffect, Suspense, useState } from 'react';

import { Outlet } from 'react-router-dom';

import Footer from '../components/layout-component/Footer';
import Header from '../components/layout-component/Header';



const postscribe = require('postscribe');

const BOLayout: React.FC = () => {



	useEffect(() => {
		postscribe('#jsrun', '<script src="./bootstrap-italia/dist/js/bootstrap-italia.bundle.min.js"></script>');

	}, []);

	return (
		<>
			<Header  />
		<div id="page-content"className="container-fluid" style={{flex: '1 0 auto'}}>
      
				<div className="row flex-xl-nowrap" style={{width:'100%'}}>
			{/*	<div className="col-12 col-md-3 col-xl-2 bd-sidebar">
					</div> */}
					{/*  <ContentExample/> */}
					<main style={{margin:'0 auto', display:'table'}} className="col-12 col-md-9 col-xl-8 py-md-3 px-md-3 bd-content">

						<Outlet />

					</main>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default BOLayout;
