import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';

export default function Prodotti() {
    const { http } = AuthUser();
    const [prodotti, setProdotti] = useState([]);
    const [imgbase64, setImgbase64] = useState(null);
    const [states, setstates] = useState({
        nome: null,
        descrizione: null,
        tipo: null,
        immagine: null,

    });

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    useEffect(() => {
        fetchProdottiDetail();
    }, []);

    const fetchProdottiDetail = () => {

        http.get('/prodotti').then((res) => {
            setProdotti(res.data);
        });
    }
    const handleInputChange = (evt) => {

        const s = states;
        s[evt.target.name] = evt.target.value;
        setstates(s);

    }

    const handleInputFile = (evt) => {
        const s = states;
        s[evt.target.name] = evt.target.files[0];
        setstates(s);

        const reader = new FileReader();
        reader.onload = (obj) => {
            console.log('file', obj);
            setImgbase64(obj.target.result);
        }
        reader.readAsDataURL(evt.target.files[0])

        // setImgbase64( Buffer.from( new FileReader() ).toString("base64")  );
    }
    const saveProdottiDetail = () => {

        const f = new FormData();
        f.append('file', states.immagine);
        f.append('nome', states.nome);
        f.append('descrizione', states.descrizione);
        f.append('tipo', states.tipo);
        http.post('prodotti', f).then((response) => {
            if (response.data.success) {
                Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });
                window.$('.bd-example-modal-lg').modal('toggle');
                fetchProdottiDetail();
            }
        }).catch((reason) => {

            Toast.fire({
                title: reason.response.data.message,
                icon: 'error'
            });
        })

    }

    return (
        <div>
            <h1 className='mb-4 mt-4'>Prodotti</h1>
            <div>
                <div className="row">
                    <button
                        type="button"
                        className="btn btn-primary "
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg">
                        Crea Prodotto

                    </button>
                </div>
                <div className="row">
                    {prodotti != null &&
                        prodotti.map((p) =>
                            <div style={{ float: 'left', maxWidth: 280, margin: 20 }} className="card-wrapper">
                                <a className="card card-img no-after special-card" href="#">
                                    <div className="img-responsive-wrapper">
                                        <div className="img-responsive">
                                            <figure className="img-wrapper">
                                                <img
                                                    src={'http://oktoberfestapi.guides4you.it/immagini/' + p.immagine}
                                                    title="img title"
                                                    alt="imagealt" />
                                            </figure>
                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {p.tipo}
                                        </h5>
                                        <h5 className="card-title">
                                            {p.nome}
                                        </h5>
                                   
                                        <h5 className="card-title">
                                            {p.descrizione}
                                        </h5>
                                    </div>

                                </a>
                            </div>

                        )
                    }
                </div>


                <div
                    className="modal fade bd-example-modal-lg"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content" style={{ padding: 30 }} >
                            <h5
                                className="modal-title"
                                id="myLargeModalLabel" style={{ margin: 20 }}>Crea Prodotto </h5>
                            <div className="form-group">
                                <label htmlFor="nome">Nome</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nome"
                                    name="nome"
                                    defaultValue={states.nome} onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label for="descrizione">Descrizione</label>
                                <textarea id="descrizione" name="descrizione" onChange={handleInputChange} rows="4">
                                    {states.descrizione}
                                </textarea>
                            </div>

                            <div className="select-wrapper">
                                <label for="defaultSelect">Tipo Prodotto</label>
                                <select id="tipo" name="tipo" defaultValue={states.tipo} onChange={handleInputChange}>
                                    <option selected="" value="">Scegli un'opzione</option>
                                    <option value="Menù">Menù</option>
                                    <option value="Bevanda">Bevanda</option>
                                    <option value="Gadget">Gadget</option>
                                </select>
                            </div>
                           
                            <br />
                            <ul className="upload-pictures-wall">
                                <li>
                                    <input type="file" onChange={handleInputFile} name="immagine" id="immagine" className="upload pictures-wall" />
                                    <label for="immagine">
                                        <svg className="icon icon-sm" aria-hidden="true">
                                            <use href="/bootstrap-italia/dist/svg/sprite.svg#it-plus" />
                                        </svg>
                                        <span>Add photo</span>
                                    </label>
                                </li>
                                {imgbase64 &&
                                    <li >
                                        <div className="upload-image">
                                            <img src={imgbase64} alt="imagealt" />
                                        </div>
                                    </li>
                                }
                            </ul>
                            <button
                                onClick={saveProdottiDetail}
                                type="button"
                                className="btn btn-primary "
                                data-target=".bd-example-modal-lg">
                                Salva
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <script>
            </script>
        </div>
    )
}