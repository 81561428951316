import axios from 'axios';
import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import HeaderContent from '../../../components/shared-component/HeaderContent'
import IpSelector from '../../../components/shared-component/IpSelector';
import RoleSelector from '../../../components/shared-component/RoleSelector';
import Swal from 'sweetalert2';


const links = [
    { url: '/', name: 'Home', active: false },
   
    { url: '/permessi', name: 'Permessi', active: true },
];

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
});

const Permessi = (props) => {
    const http = AuthUser().http;

    const  [menus,setmenus]= useState([]);
    const  [ipaddress,setipaddress]= useState("1");
    const  [role,setrole]= useState("1");

    const  [idIp,setidIp]= useState("");


    useEffect(()=> {
        http.get(`menupermission/${role}/ip/${ipaddress}`, {}).then(response => {
           
            setmenus(response.data );
           
            
        });

    },[])

  const  handleRoleChange = (roleId) => {

        http.get(`menupermission/${roleId}/ip/${ipaddress}`).then((response) => {
            setrole( roleId);
            setmenus(response.data);
        })

    }

  const  handleIpChange = (ipId) => {
        http.get(`menupermission/${role}/ip/${idIp}`).then((response) => {
            setipaddress( ipId);
             setmenus(response.data);
        })
    }
        return (
            <div>
                <HeaderContent title="Permessi alle Funzioni" subtitle="crea e modifica permessi di navigazione" links={links} />

                {/* Main content */}
                <section className="content">
                    {/* Default box */}
                    <div className="card ">
                        <div className="card-header with-border">
                            <div className="card-tools pull-right">
                                <button type="button" className="btn btn-box-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fa fa-minus" /></button>
                                <button type="button" className="btn btn-box-tool" data-card-widget="remove" title="Remove">
                                    <i className="fa fa-times" /></button>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <RoleSelector defaultValue={role} onChange={handleRoleChange} />
                                </div>
                                <div className="col-md-4">
                                    <IpSelector defaultValue={ipaddress} onChange={handleIpChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="card">
                           
                                {/* /.box-header */}
                                <div className="card-body">
                                    {menus.map((m, i) => {
                                        return (
                                            <div className="card-header" key={i} >
                                                <h4 >{m.menu.name}</h4>
                                                   <div  key={'r'+i} class="row">
                                                    {m.submenu.map((s, ii) => {
                                                         return (
                                                            <div class="form-check form-check-inline">
                                                              <input id={ii+s.name} type="checkbox"  checked={s.active} onChange={(evt)=>{
                                                                           
                                                                           const params = {ruolo: role, funzione:s.id,ipaddress:ipaddress, delete: evt.target.checked?0:1}
                                                                           http.post('menupermission/update',{...params}).then((response)=>{
                                                                               http.get(`menupermission/${role}/ip/${ipaddress}`).then((response2) => {
                                                                                   
                                                                                   setmenus(response2.data );
                                                                               });
                                                                               Toast.fire({
                                                                                   title: response.data.text,
                                                                                   icon: 'success'
                                                                               });

                                                                           })
                                                                       }} />
                                                              <label for={ii+s.name} >
                                                                    {s.name}
                                                                </label>
                                                            </div>
                                                                                                             
                                                        )
                                                    })}
                                                  </div>
                                              
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* /.box-body */}
                            </div>
                        </div>
                        {/* /.box-body */}
                        <div className="card-footer">
                        </div>
                        {/* /.box-footer*/}
                    </div>
                    {/* /.box */}
                </section>
                {/* /.content */}
            </div>

        
        )
}
export default Permessi;