
import React, { useState, useEffect } from 'react';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import AuthUser from '../../../components/AuthUser';

const links = [
    { url: '/', name: 'Home', active: false },
    { url: '/menu', name: 'Menù', active: false },
    { url: '/menu/nuovomenu', name: 'Nuovo Menù', active: true },
]


const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})


const NuovoMenu = (props) => {
    const http = AuthUser().http;


    const  [menus,setmenus]= useState([]);

    useEffect(()=> {
        http.get('/menu', {}).then(response => {
                
            setmenus(response.data);
            
        });

    },[])

    
 const   itemDelete = (id) => {

    http.delete(`menu/${id}`).then((response)=>{
        if (response.data.success) {
            Toast.fire({
                title: response.data.text,
                icon: 'success'
            });
                 
        }
    })

       
    }
    
    
        return (
            <div>
                <HeaderContent title="Gestione Menù" subtitle="crea e modifica il menù di navigazione" links={links} />
                <div>
                    <section className="content">
                        {/* Default box */}
                        <div className="card">
                      
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-header">
                                    <div  className="row">
                                    <div  className="col-sm"> 
                                    <h3 className="card-title">Menù salvati</h3>  
                                    </div>
                                    <div  className="col-sm"> 
                                    <Link to="/app/menu/create/0" className="col-lg-offset-5 btn btn-success">Nuovo Menù</Link>
                                    </div>
                                    </div>
                                             
                                    </div>
                                    {/* /.box-header */}
                                    <div className="card-body">
                                       
                                        <table id="tablemenu" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Id Menù</th>
                                                    <th>Nome Menù</th>
                                                    <th>Icona</th>
                                                    <th>Posizione</th>
                                                    <th>Azioni</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {menus.map((m, i)=>{
                                                    return (
                                                        <tr key={m.id}>
                                                            <td>{m.id}</td>
                                                            <td>{m.name}</td>
                                                            <td><i className={`fa ${m.class_icon}`}></i></td>
                                                            <td>{m.posizione}</td>
                                                            <td><span>
                                                                <Link to={`/app/menu/create/${m.id}`}><svg className="icon"><use href="/bootstrap-italia/dist/svg/sprite.svg#it-pencil" /></svg></Link>
                                                            </span>
                                                            <span><Link to="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                Swal.fire({
                                                                    title: 'Vuoi eliminare il menù ?',
                                                                    showDenyButton: false,
                                                                    showCancelButton: true,
                                                                    confirmButtonText: `OK`,
                                                                    
                                                                  }).then((result) => {
                                                                    /* Read more about isConfirmed, isDenied below */
                                                                    if (result.isConfirmed) {
                                                                        itemDelete(m.id);
                                                                    } 
                                                                  })
                                                               
                                                            }}> <svg className="icon"><use href="/bootstrap-italia/dist/svg/sprite.svg#it-delete" /></svg></Link>
                                                            </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) }
                                            </tbody> 
                                        </table>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.box-body */}
                            <div className="card-footer">
                            </div>
                            {/* /.box-footer*/}
                        </div>
                        {/* /.box */}
                    </section>
                    {/* /.content */}
                </div>



            </div>
        )
    
}
export default NuovoMenu;