
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthUser from '../../../components/AuthUser';
import moment from "moment";

const Views: React.FC = () => {

	const [dati, setDati] = useState({});

	const { http } = AuthUser();
	const { localtoken, logout } = AuthUser();
	const logoutUser = () => {
		if (localtoken != null) {
			logout();
		}
	}

	const incassostripe = () => {

		var s = document.getElementById('dataprenotazione');

		http.get('/IncassoStripe?data=' + s.value)
			.then(data => {
				setDati(data.data.dati);
				//	console.log('nuovi ', data.data.dati);
			});

	}

	const prenotazioniSpecial = () => {
		
		var s = document.getElementById('dataprenotazione');
		http.get('/IncassoSpecial?data=' + s.value)
		.then(data => {
			setDati(data.data.dati);
			//	console.log('nuovi ', data.data.dati);
		});

	}
	const incassoStripeTotale = () => {
		
		
		var s = document.getElementById('dataprenotazione');
		http.get('/dettagliospecial?data=' + s.value)
		.then(data => {
			setDati(data.data.dati);
			//	console.log('nuovi ', data.data.dati);
		});
	}

	useEffect(() => {

		console.log('nuovi dati', dati);

	}, [dati]);

	return (
		<div className="row">
			<div className="row">
				<div className="col-md-6 col-xl-3">
					<div class="form-group">
					Data  <br />
                        <input id="dataprenotazione" value={moment().format('YYYY-MM-DD')} disabled/>
                      
				{/* 	Data  <br />   <select style={{ fontFamily: 'Montserrat', color: 'black' }} id="dataprenotazione" defaultValue="" >
							<option value="">
								Scegli un'opzione
							</option>

							<option value="2022-09-16">16/09/2022</option>
							<option value="2022-09-17">17/09/2022</option>
							<option value="2022-09-18">18/09/2022</option>
							<option value="2022-09-19">19/09/2022</option>
							<option value="2022-09-20">20/09/2022</option>
							<option value="2022-09-21">21/09/2022</option>
							<option value="2022-09-22">22/09/2022</option>
							<option value="2022-09-23">23/09/2022</option>
							<option value="2022-09-24">24/09/2022</option>
							<option value="2022-09-25">25/09/2022</option>
							<option value="2022-09-26">26/09/2022</option>
							<option value="2022-09-27">27/09/2022</option>
							<option value="2022-09-28">28/09/2022</option>
							<option value="2022-09-29">29/09/2022</option>
							<option value="2022-09-30">30/09/2022</option>
							<option value="2022-10-01">01/10/2022</option>
							<option value="2022-10-02">02/10/2022</option>

						</select>
						*/}	
					</div>
				</div>
				<div className="col-md-6 col-xl-3">
					<button onClick={() => incassostripe()}>Incasso Stripe</button> <br /><br />

				</div>
				<div className="col-md-6 col-xl-3">
					<button onClick={() => prenotazioniSpecial()}>Prenotazioni Special</button> <br /><br />

				</div>
				<div className="col-md-6 col-xl-3">
					<button onClick={() => incassoStripeTotale()}>Dettaglio Special</button> <br /><br />

				</div>
			</div>
			<div className="row">
				{
					Object.keys(dati).length > 0 && <table style={{ border: 'solid' }}>
						{
							Object.keys(dati[0]).map(p => (
								<th >{p}</th>
							))
						}

						{
							Object.keys(dati).map(d => (
								<tr >
									{
										Object.entries(dati[d]).map((key) => (
											<td style={{ border: 'solid 1px' }} >{key[1]}</td>
										))
									}

								</tr>
							))
						}

					</table>
				}

			</div>
		</div>
	);

}

export default Views;