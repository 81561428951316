import * as React from "react";
import AuthUser from '../AuthUser';
import { useEffect, useState } from 'react';
import './assets/styles/App.css'
import { useDispatch } from "react-redux";
import { setshowHF } from '../../features/user/userSlice';
import moment from "moment";
import Swal from 'sweetalert2';

type DraggableProps = {
  data?: Record<string, any>;
  children?: (isDragging: boolean) => React.ReactNode;
};

const Draggable = ({ data, children }: DraggableProps): JSX.Element => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(true);

    if (data) {
      e.dataTransfer.setData("text/x-item", JSON.stringify({ data }));
    }
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  return (
    <div draggable onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      {children && children(isDragging)}
    </div>
  );
};

type DroppableProps = {
  className?: string;
  children?: (isHovered: boolean) => React.ReactNode;
  onDrop?: (data: any) => void;
};

const Droppable = ({
  className,
  children,
  onDrop
}: DroppableProps): JSX.Element => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.types.includes("text/x-item")) {
      //    console.log('handleDragEnter',e);
      e.preventDefault();
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = "move";
    //  console.log('handleDragOver',e);
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    //  console.log('handleDragLeave',e);
    setIsHovered(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    console.log('handleDrop', e);

    e.preventDefault();
    setIsHovered(false);

    if (e.dataTransfer.types.includes("text/x-item")) {

      const { data } = JSON.parse(e.dataTransfer.getData("text/x-item"));

      console.log('datitavolo ', e.target.children);
      console.log('datiprenotazione ', data);

      onDrop?.(data);
    }
  };

  return (
    <div
      className={className}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children && children(isHovered)}
    </div>
  );
};

const App = (): JSX.Element => {

  const [oggi, setDate] = useState(moment());
  const [full, setFull] = useState(true);
  const [display, setdisplay] = useState('none');
  const { http } = AuthUser();
  const dispatch = useDispatch();
  const [transform, setTransform] = React.useState({
    transform: 1,

  });

  const [droppables, setDroppables] = React.useState([]);

  const [draggables, setDraggables] = React.useState([]);

  useEffect(() => {
    (async () => {
      let result = await getTavoli();
      console.log('useeffec Tavoli', result);
      setDroppables(result);
    })()
  }, []);

  const coloratavoli = () => {

    let d = '';
    let p = 0;
    draggables.map((m) => {
      console.log('draggables', m);
      if (d != m.droppableId) {
        d = m.droppableId;
        p = 0;
        p += parseInt(m.posti);
        if (p == 8)
          document.getElementById('tav' + m.droppableId).style.background = '#0080007d';
        else
          document.getElementById('tav' + m.droppableId).style.background = 'white';
      } else {
        d = m.droppableId;
        p += parseInt(m.posti);
        if (p == 8)
          document.getElementById('tav' + m.droppableId).style.background = '#0080007d';
        else
          document.getElementById('tav' + m.droppableId).style.background = 'white';
      }
    });
  }


  const getTavoli = async () => {

    const result = await http.post('gettavoli', {
      dataprenotazione: ''
    });
    return result.data;

  }


  const salvaprenotazioni = () => {

    http.post(`salvaprenotazioni`, { dataprenotazione: oggi, tipo: 'Platea', prenotati: draggables }).then((response) => {

      Swal.fire({
        title: response.data.text,
        icon: 'success'
      });

    }).catch((reason) => {
      Swal.fire({
        position: 'top-end',
        title: reason,
        icon: 'error',
      });
    })

  }


  const getPrenotazioni = (genera) => {

    if (moment(moment(oggi).format('YYYY-MM-DD') + ' 10:00') > moment()) {
      Swal.fire({
        position: 'top-end',
        title: 'Attendere le 17:00',
        icon: 'error',
      });

    } else {

      http.post(`getprenotazioni`, { dataprenotazione: oggi, tipo: 'Platea', genera: genera }).then((response) => {

        const boxes = document.querySelectorAll('.w-64');

        boxes.forEach(box => {
          box.style.backgroundColor = 'white';

        });

        console.log('data', response.data);

        setDraggables(response.data);



      }).catch((reason) => {
        Swal.fire({
          position: 'top-end',
          title: reason,
          icon: 'error',
        });
      })
    }
  }

  const ieri = () => {

    //  console.log(oggi.add(-1, 'days'));
    let d = moment(oggi).add(-1, 'days');
    setDate(d);
    // setDate(d);


  };
  const domani = () => {

    let d = moment(oggi).add(1, 'days');
    console.log(d);
    setDate(d);
  };

  const oggis = () => {
    setdisplay('inline');
    // getPrenotazioni(false);

  };


  const fullZoom = () => {
    // console.log('ref', ref);
    setFull(!full);
    dispatch(setshowHF(full));

  };

  const zoomIn = () => {
    // console.log('ref', ref);
    const trans = transform.transform;
    const zoomIn = trans + 0.1 > 2 ? 2 : trans + 0.1;
    setTransform({
      transform: zoomIn
    });
  };

  const zoomOut = () => {

    const trans = transform.transform;
    const zoomOut = trans - 0.1 <= 0.1 ? 0.1 : trans - 0.1;

    setTransform(
      {
        transform: zoomOut
      }
    );
  };

  const resetZoom = () => {
    setTransform({
      transform: 1
    });
  };



  const moveTask = (id: number, droppableId: number, droppableIdlast: number) => {

    // annulla drop
    // return;
    let pd = 0;
    let pp = 0;
    let capienzaTavolo = 0;

    droppables.map((t) => {
      if (t.id == droppableId)
        capienzaTavolo = t.posti;
    });

    draggables.map((d) => {
      if (d.droppableId == droppableId) {

        //     console.log('uguale 1',d);

        pd += parseInt(d.posti);
      }
      if (d.id == id) {
        //    console.log('uguale 2',d);
        pp = parseInt(d.posti);
      }
    });

    if (parseInt(pd) + parseInt(pp) > parseInt(capienzaTavolo)) {

      alert('Spostamento non permesso Capienza non disponibile');
    } else {
      //  console.log(document.querySelector('#tav' + droppableIdlast + ' .droparea .h-full .p-2').children);
      var prenold = document.querySelector('#tav' + droppableIdlast + ' .droparea .h-full .p-2').childElementCount;
      if (parseInt(pd) + parseInt(pp) == 8) {
        document.getElementById('tav' + droppableId).style.background = '#0080007d';

      } else {
        if (parseInt(pd) + parseInt(pp) > 0) {
          document.getElementById('tav' + droppableId).style.background = 'white';
        }

        // console.log(document.querySelectorAll('#tav'+droppableIdlast+ 'div').length);
      }
      if (prenold == 1) {
        document.getElementById('tav' + droppableIdlast).style.background = 'white';
      } else {
        document.getElementById('tav' + droppableIdlast).style.background = 'white';
      }

      const i = draggables.findIndex(
        (draggable) =>
          draggable.id === id && draggable.droppableId !== droppableId
      );
      if (i !== -1) {
        setDraggables((draggables) => [
          ...draggables.slice(0, i),
          { ...draggables[i], droppableId },
          ...draggables.slice(i + 1)
        ]);
      }
    }
  };

  return (
    <div style={{
      overflow: 'auto', width: 1600,


    }} >
      <div className="sidebars">
        <button className="btn" onClick={() => zoomIn()}>
          Zoom In
        </button>
        <button
          className="btn"
          onClick={() => zoomOut()}
        >
          Zoom Out
        </button>
        <button
          className="btn"
          onClick={() => resetZoom()}
        >
          Reset
        </button>
        <button
          className="btn"
          onClick={() => fullZoom()}
        >
          FullScreen
        </button>
        <button
          style={{ display: display }}
          className="btn"
          onClick={() => ieri()}
        >
          {'<--'}
        </button>
        <button

          onClick={() => oggis()}
        >{oggi.format('DD-MM-YYYY')}
        </button>
        <button
          style={{ display: display }}
          className="btn"
          onClick={() => domani()}
        >
          {'-->'}

        </button>

        <button
          className="btn"
          onClick={() => getPrenotazioni(true)}
        >
          Assegna
        </button>

        <button
          className="btn"
          onClick={() => coloratavoli()}
        >
          Colora
        </button>

        <button
          className="btn"
          onClick={() => salvaprenotazioni()}
        >
          Salva
        </button>
      </div>
      <main style={{ width: 4800, transformOrigin: '0 0', transform: `scale(${transform.transform})` }} className="min-h-screen p-6 flex space-x-4 bg-gray-50">
        {droppables.map(({ id, nome, posti, color, orientamento , amount}) => (
          <div style={{ background: color }} key={id} id={'tav' + id} className={"w-64 flex flex-col select-none " + orientamento}>
            <div className="px-4 py-2 bg-gray-200 font-bold">{nome} - posti:{posti}</div>
            <Droppable
              className="flex-auto droparea"
              onDrop={(data) => {
                moveTask(data.id, id, data.droppableId)
              }}
            >
              {(isHovered) => (
                <div
                  className={`h-full ${isHovered ? "bg-blue-50" : "bg-gray-100"}`}
                >
                  <div className="p-2 space-y-2">
                    {
                      draggables
                        .filter(({ droppableId }) => droppableId === id)
                        .map(({ id, droppableId, name, posti, color, amount }) => {

                          //     console.log(posti, droppableId);
                          return (
                            <Draggable key={id} data={{ id, droppableId, posti, color }}>
                              {(isDragging) => {
                                return <div style={{ background: 'rgb(' + color + ')' }}
                                  className={`prenot${posti} p-2 tooltips rounded-md border-2  ${isDragging
                                    ? "bg-gray-300 border-dashed"
                                    : "bg-gray-200 border-transparent"
                                    }`}
                                >
                                  {posti} - {amount} €
                                  <span className="tooltiptext">{name + ' - posti prenotati ' + posti}</span>
                                </div>
                              }

                              }
                            </Draggable>
                          )
                        }

                        )}
                  </div>
                </div>
              )
              }
            </Droppable>
          </div>
        ))}
      </main>
    </div>
  );
};

export default App;
