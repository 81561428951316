

const InputField = (props) => {

    return (
        <div className="form-group">
        <label className={props.classlabel?props.classlabel:''}   htmlFor={props.id}>{props.label}</label>
        <input type={props.type?props.type:'text'} className="form-control " id={props.id}  name={props.id}   value={props.form.values[props.id]} onChange={(evt)=>{
                                    props.form.setFieldValue(props.id, evt.target.value)
                                    props.form.validateField(props.id)       
                                    }}/>
                                { <small className="error">{props.form.errors[props.id]}</small>}
    </div>
    )

};

export default InputField;