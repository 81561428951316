import React, { useEffect, Suspense, useState } from 'react';
import { selectUser } from '../../features/user/userSlice';
import Aside from '../../components/layout-component/Aside';
import { useAppSelector } from '../../app/hooks';


const NavOrizBar: React.FC = () => {

  const user = useAppSelector(selectUser);

	useEffect(() => {
	
	}, []);


    return (
      <>
<div className="navbar-collapsable" id="nav10" >
<div className="overlay"></div>
<div className="close-div sr-only">
  <button className="btn close-menu" type="button"><span className="it-close"></span>close</button>
</div>
<div className="menu-wrapper">

  {user.isLogged && <Aside />}
  { /* !user.isLogged &&   <ul className="navbar-nav">
  <li className="nav-item active"><a className="nav-link active" href="#" aria-current="page"><span>Home</span></a></li>
    </ul>
    
   /*/ }

{/*    <ul className="navbar-nav navbar-secondary">
    <li className="nav-item">
      <a className="nav-link" href="#"> Argomento in evidenza 1</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="#"> Argomento in evidenza 2</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="#"> Argomento in evidenza 3</a>
    </li>
    <li className="nav-item ">
      <a className="nav-link " href="#">
        <span className="font-weight-bold">Tutti gli argomenti...</span>
        
      </a>
    </li>
  </ul>
  */}
</div>
</div>

</>

)

}
export default NavOrizBar;
