import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import itLocale from '@fullcalendar/core/locales/it';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import AuthUser from '../../AuthUser';
import moment from "moment";
import shortid from 'shortid';

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useNavigate } from 'react-router-dom';

export default function AppCall() {
  const navigate = useNavigate();
  const { http } = AuthUser();
  const [events, setEvents] = useState([])
  const [calendarComponentRef, setcomponent] = useState({});
  const [states, setstates] = useState({});
  const [evento, setInsertEvento] = useState({

    prodotto: {},
    posti: 0,
    datainizio: null,
    datafine: null,
    orainiziogiorno: null,
    orafinegiorno: null,
    orafineprenoazioni: null

  });


  const Toast = Alert.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
  })


  const fetchEventiDetail = () => {

    http.get('/getcalendar').then((res) => {

      let newEvents = new Array;
      let dateins = {};

      res.data != null &&
        res.data.map((p) => {
          if (dateins[moment(p.dataorainizio).format('DDMMYY')] !== undefined) {
            dateins[moment(p.dataorainizio).format('DDMMYY')].posti += p.posti;
            if (new Date(dateins[moment(p.dataorainizio).format('DDMMYY')].fineprenotazione) < new Date(p.fineprenotazioni))
              dateins[moment(p.dataorainizio).format('DDMMYY')].fineprenotazione = p.fineprenotazioni;
          } else {
            dateins[moment(p.dataorainizio).format('DDMMYY')] = {
              posti: p.posti, fineprenotazione: p.fineprenotazioni,
              end: p.dataorafine,
              start: p.dataorainizio
            }
          }

        });

    //    console.log(dateins);

        {Object.keys(dateins).map(key =>{

          let event = {};
          if (dateins[key].posti == 0) {
            event = {
              urlimg:'/prenotachiusa.png',
              posti:dateins[key].posti,
              fineprenotazioni:dateins[key].fineprenotazione,
              title: 'Prenotazioni Terminate',
              start: new Date(dateins[key].start),
              end: new Date(dateins[key].end),
              backgroundColor: 'red'
            }
          } else if (new Date() > new Date(dateins[key].fineprenotazione)) {
            event = {
              urlimg:'/prenotachiusa.png',
              posti:dateins[key].posti,
              fineprenotazioni:dateins[key].fineprenotazione,
              title: 'Prenotazioni Chiuse',
              start: new Date(dateins[key].start),
              end: new Date(dateins[key].end).getDate, 
              backgroundColor: 'red'
            }
          } else {
            event = {
              urlimg:'/prenota.png',
              posti:dateins[key].posti,
              fineprenotazioni:dateins[key].fineprenotazione,
              title: 'Prenotazioni disponibili',
              start: new Date(dateins[key].start),
              end: new Date(dateins[key].end),
              backgroundColor: 'green'
            }
          }

          newEvents.push(
            event
          );
        });}

      console.log(newEvents);
      setEvents(newEvents);

    });
  }

  /**
   * adding dragable properties to external events through javascript
   */
  useEffect(() => {

    setcomponent(React.createRef());

    fetchEventiDetail();

  }, []);

  function renderEventContent(eventInfo) {
    return (
      <div style={{padding:5,marginTop:-30}}>
        <p>{/*eventInfo.event.title//*/}</p>
      <img className="eventimage" src={eventInfo.event.extendedProps.urlimg} />
      </div>
    )
  }

  /**
   * when we click on event we are displaying event details
   */
  const eventClick = eventC => {

    if(moment(eventC+' 17:00')< moment()){
     
      Toast.fire({
        title: 'Prenotazioni Chiuse',
        icon: 'error'
    });

    }else{
      navigate('prenota',{state:{dataprenotazione:eventC}});
    }
   
  };

  return (
    <div className="animated fadeIn p-4 demo-app">
      <Row>

        <Col className='paddzero' lg={12} sm={12} md={12}>

          <table className="calenda fc-scrollgrid  fc-scrollgrid-liquid">
            <thead ><tr  ><th > mar </th> <th > mer</th> <th > gio</th> <th >ven</th> <th >sab</th> <th > dom</th><th > lun</th> </tr>
            </thead>
            <tbody >
            <tr ><td > 6 </td> <td > 7</td> <td > 8</td> <td >9</td> <td >10</td> <td > 11</td><td > 12</td> </tr>
         
            <tr ><td > 13 </td> <td > 14</td> <td > 15</td> 
            <td ><div>16</div> <img onClick={() => eventClick('2022-09-16')} src={moment('2022-09-16 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>17</div> <img onClick={() => eventClick('2022-09-17')} src={moment('2022-09-17 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>18</div> <img onClick={() => eventClick('2022-09-18')} src={moment('2022-09-18 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>19</div> <img onClick={() => eventClick('2022-09-19')} src={moment('2022-09-19 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            </tr>

            <tr >
            <td ><div>20</div> <img onClick={() => eventClick('2022-09-20')} src={moment('2022-09-20 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>21</div> <img onClick={() => eventClick('2022-09-21')} src={moment('2022-09-21 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>22</div> <img onClick={() => eventClick('2022-09-22')} src={moment('2022-09-22 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>23</div> <img onClick={() => eventClick('2022-09-23')} src={moment('2022-09-23 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>24</div> <img onClick={() => eventClick('2022-09-24')} src={moment('2022-09-24 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>25</div> <img onClick={() => eventClick('2022-09-25')} src={moment('2022-09-25 17:30')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>26</div> <img onClick={() => eventClick('2022-09-26')} src={moment('2022-09-26 17:30')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            </tr>

            <tr >
            <td ><div>27</div> <img onClick={() => eventClick('2022-09-27')} src={moment('2022-09-27 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>28</div> <img onClick={() => eventClick('2022-09-28')} src={moment('2022-09-28 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>29</div> <img onClick={() => eventClick('2022-09-29')} src={moment('2022-09-29 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>30</div> <img onClick={() => eventClick('2022-09-30')} src={moment('2022-09-30 16:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>01</div> <img onClick={() => eventClick('2022-10-01')} src={moment('2022-10-01 14:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>02</div> <img onClick={() => eventClick('2022-10-02')} src={moment('2022-10-02 17:00')< moment()? "prenotachiusa.png":"prenota.png" }></img> </td> 
            <td ><div>03</div>  </td> 
            </tr>
            </tbody>
          </table>
         
                       
        </Col>
      </Row>

    </div>
  );

}

//const rootElement = document.getElementById("root");

//ReactDOM.render(<AppCall />, rootElement);


