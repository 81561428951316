import React, { Component } from 'react'
import Cookiebar from './Cookiebar'
import { useAppSelector } from '../../app/hooks';
import { selectUser, setIsLogged } from '../../features/user/userSlice';

const Footer: React.FC = (  ) => {
  const user = useAppSelector(selectUser);
        return (
<>
{user.isLogged && user.showHF &&
<footer className="it-footer mt-auto" style={{flexShrink: 0, width: user.isLogged?'100%':'auto'}} >
  <div className="it-footer-main">
    <div className="container">
      <section>
        <div className="row clearfix">
          <div className="col-sm-12">
                <div className="it-brand-text">
                  <h2 style={{color:'white'}}>Oktoberfest Calabria 2022</h2>
                </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
 }
</>
        )
}

export default Footer;
