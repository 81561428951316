import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';

export default function Special() {

    const navigate = useNavigate();
    const location = useLocation();
    const { http } = AuthUser();
    const [bigliettospecial, setbigliettospecial] = useState(null);


    const [dati, setdati] = useState(

        {
            'nome': '',
            'cognome': '',
            'telefono': '',
        }

    );

    const [prodotti, setprodotti] = useState(

        {
            'posti': { qt: 0, prezzo: 0 },
            'piatto': { qt: 0, prezzo: 15 },
            'birra': { qt: 0, prezzo: 11 },
            'dolce': { qt: 0, prezzo: 5 },
            'brezel': { qt: 0, prezzo: 2 },
            'fusto': { qt: 0, prezzo: 210 },
            'tagliere': { qt: 0, prezzo: 10 },
            'patatine': { qt: 0, prezzo: 4 },
        }

    );
    const [verificadati, setverificadati] = useState(false);
    const [fasciaorarioa, setfascia] = useState('');
    const [tipo, settipo] = useState('');
    const [note, setnote] = useState('');
    const [dataprenotazione, setprenotazione] = useState('');
    const [totale, settotale] = useState(0);
    useEffect(() => {
        //  console.log(states);
        //  fetchProdottiDetail();
    }, [totale]);


    const fetchpaga = async () => {


        const result = await http.post('prenotaspecial', {
            special: true,
            totale: totale,
            tipo: tipo,
            note: note,
            dati: dati,
            prenotazione: prodotti,
            data: dataprenotazione,
            fasciaoraria: fasciaorarioa

        });

        setbigliettospecial(result.data.success);

        return result.data;
    }

    const validadati =()=>{

    //    console.log(prodotti);
       
        if (prodotti['posti']['qt'] > 0  && dati.nome != '' &&
         dati.cognome!= '' &&
         dati.telefono!= '' &&
         fasciaorarioa !='' &&
         tipo !='' &&
         dataprenotazione !='' 
            
        ) { setverificadati(true) 
            console.log('validadati','true');
        } else { 
            setverificadati(false)
            console.log('validadati','false');
        };
    }

    const handleInputChangeNote = (evt) => {
        setnote(evt.target.value);
        validadati();
    }
    const handleInputChangedata = (evt) => {
        setprenotazione(evt.target.value);
        validadati();
    }

    const handleTotale = () => {
       
        var total = 0;

        {
            Object.keys(prodotti).map((key) => (
                total += prodotti[key]['qt'] * prodotti[key]['prezzo']

            ))
        }
        settotale(total);

        var s = document.getElementById('totale');
        s.value = total;
        validadati();
    }

    const handleInputChangedati = (evt) => {
        
        dati[evt.target.name] = evt.target.value;
        validadati();
    }

    const handleInputChange = (evt) => {
       
        prodotti[evt.target.name]['qt'] = evt.target.value;
        handleTotale();
        validadati();

    }


    const handleInputChangetipo = (evt) => {
        settipo(evt.target.value);
        validadati();

    }
    const handleInputChangefascia = (evt) => {
        setfascia(evt.target.value);

        validadati();

    }
    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    return (
        <div>
            <div className='row'>
                <div className='col-sm'>
                    <div class="form-group">
                        Data  <br />   <select style={{ fontFamily: 'Montserrat', color: 'black' }} id="dataprenotazione" defaultValue="" onChange={handleInputChangedata}>
                            <option value="">
                                Scegli un'opzione
                            </option>
                           
                            <option value="2022-09-16">16/09/2022</option>
                            <option value="2022-09-17">17/09/2022</option>
                            <option value="2022-09-18">18/09/2022</option>
                            <option value="2022-09-19">19/09/2022</option>
                            <option value="2022-09-20">20/09/2022</option>
                            <option value="2022-09-21">21/09/2022</option>
                            <option value="2022-09-22">22/09/2022</option>
                            <option value="2022-09-23">23/09/2022</option>
                            <option value="2022-09-24">24/09/2022</option>
                            <option value="2022-09-25">25/09/2022</option>
                            <option value="2022-09-26">26/09/2022</option>
                            <option value="2022-09-27">27/09/2022</option>
                            <option value="2022-09-28">28/09/2022</option>
                            <option value="2022-09-29">29/09/2022</option>
                            <option value="2022-09-30">30/09/2022</option>
                            <option value="2022-10-01">01/10/2022</option>
                            <option value="2022-10-02">02/10/2022</option>

                        </select>
                    </div>
                </div>
                <div className='col-sm'>
                    <div class="form-group">
                        fascia oraria <br />  <select style={{ fontFamily: 'Montserrat', color: 'black' }} name="fasciaoraria" id="fasciaoraria" defaultValue="" onChange={handleInputChangefascia}>
                            <option value="">
                                Scegli un'opzione
                            </option>
                            <option value="19:30">19:30</option>
                            <option value="20:00">20:00</option>
                            <option value="20:30">20:30</option>
                            <option value="21:00">21:00</option>
                            <option value="21:30">21:30</option>
                        </select>
                    </div>
                </div>
                <div className='col-sm'>
                    <div class="form-group">
                        tipo <br /> <select style={{ fontFamily: 'Montserrat', color: 'black' }} name="tipo" id="tipo" defaultValue="" onChange={handleInputChangetipo}>
                            <option value="">
                                Scegli un'opzione
                            </option>
                            <option value="platea">Platea</option>
                            <option value="vip">Vip</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="row" >
                <div className='col-sm'>
                </div>
                <div className='col-sm'>
                </div>
                <div className='col-sm'>
                </div>
            </div>
            <div className="row" style={{ marginBottom: 20 }} >
                <div className='col-sm'>
                    <div className="mobs50 mt-5">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='nome' className="input-number-label">Nome </label>
                        <span className="input-number">
                            <input type="text" id='nome' name='nome' onChange={handleInputChangedati} />
                        </span>
                    </div>
                </div>
                <div className='col-sm'>
                    <div className="mobs50 mt-5">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='cognome' className="input-number-label">Cognome </label>
                        <span className="input-number">
                            <input type="text" id='cognome' name='cognome' onChange={handleInputChangedati} />
                        </span>
                    </div>
                </div>
                <div className='col-sm'>
                    <div className="mobs50 mt-5">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='telefono' className="input-number-label">Telefono </label>
                        <span className="input-number">
                            <input type="text" id='telefono' name='telefono' onChange={handleInputChangedati} />
                        </span>
                    </div>
                </div>
            </div>

            <div className="row" >
                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='posti' className="input-number-label">Posti </label>
                        <span className="input-number">
                            <input type="number" id='posti' name='posti' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>

                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='birre' className="input-number-label">Birre </label>
                        <span className="input-number">
                            <input type="number" id='birra' name='birra' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>

                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='piatto' className="input-number-label">Piatto Principale </label>
                        <span className="input-number">
                            <input type="number" id='piatto' name='piatto' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>
                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='brezel' className="input-number-label">Brezel </label>
                        <span className="input-number">
                            <input type="number" id='brezel' name='brezel' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>
            </div>

            <div className="row" >

                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='dolce' className="input-number-label">Dolce </label>
                        <span className="input-number">
                            <input type="number" id='dolce' name='dolce' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>

                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='tagliere' className="input-number-label">Tagliere </label>
                        <span className="input-number">
                            <input type="number" id='tagliere' name='tagliere' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>
                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='patatine' className="input-number-label">Patatine </label>
                        <span className="input-number">
                            <input type="number" id='patatine' name='patatine' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>

                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='fusto' className="input-number-label">Fusto Birra </label>
                        <span className="input-number">
                            <input type="number" id='fusto' name='fusto' defaultValue={0} onChange={handleInputChange} />
                        </span>
                    </div>
                </div>
            </div>

            <div className="row" >

                <div class="form-group">
                    <label for="note">Note</label>
                    <textarea id="note" rows="3" onChange={handleInputChangeNote}></textarea>
                </div>
            </div>

            <div className='row'>
                <div style={{ padding: 10 }} className='col-sm align-self-center'>
                    <div className="w-50">
                        <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor='totale' className="input-number-label">Totale </label>
                        <span className="input-number">
                            <input type="number" id='totale' name='totale' />
                        </span>
                    </div>
                </div>
                { !bigliettospecial && verificadati && <div style={{ padding: 10 }} className='col-sm align-self-center'>

                    <div className="elementor-button-wrapper">
                        <a className="elementor-button-link elementor-button elementor-size-sm" onClick={() => fetchpaga()} role="button">
                            <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-icon elementor-align-icon-right">
                                </span>
                                <span className="elementor-button-text">Conferma Prenotazione</span>
                            </span>
                        </a>
                    </div>

                </div>}

                {
                    bigliettospecial &&
                    <div>

                        <a href={'https://oktoberfestapi.guides4you.it/api/scaricaBiglietto?i=' + bigliettospecial} className='bnt'>Scarica biglietto</a>
                        <br />
                        <br />
                        <a href={'/#/dashboard'} className='bnt'>Fine</a>

                    </div>}
            </div>
        </div>
    )
}