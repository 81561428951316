import React, { useState, useEffect } from "react";
import AuthUser from '../../../components/AuthUser';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from "moment";

function Modifica() {
  const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
  })
  const { http } = AuthUser();
  const location = useLocation();

  const salva = (evt) => {

    http.post('modificaprenotazione',
      {
        data: document.getElementById("giorno").value,
        orario: document.getElementById("orario").value,
        note: document.getElementById("note").value,
        idtransazione:location.state.idTransazione
      }
    ).then((response) => {

      console.log(response.data);

      if(response.data.success){
        Toast.fire({
          title: 'Prenotazione Modificata Correttamente',
          icon: 'success'
        });
      }else{
        Toast.fire({
          title: 'Errore Controllare Data non prenotabile',
          icon: 'error'
        });
      }
    }).catch((reason) => {

      Toast.fire({
        title: 'Errore Controllare Formato Data e ora',
        icon: 'error'
      });
    })

  }

  useEffect(() => {

  }, []);


  return (
    <div>
      <h2>Modifica Prenotazione {location.state.Biglietto}</h2>

      <div className="row">
        <div class="col-sm">
          <div class="form-group">
            <label >Nome</label><br /><br />
            <input value={location.state.Nome} type="text" id="nome" disabled />
          </div>
        </div>
        <div class="col-sm"> <div class="form-group">
          <label >Cognome</label><br /><br />
          <input value={location.state.Cognome} type="text" id="cognome" disabled />
        </div> </div>
        <div class="col-sm"><div class="form-group">
          <label for="email">Email</label><br /><br />
          <input value={location.state.Email} className="form-control" type="text" id="email" disabled />
        </div></div>
        <div class="col-sm">
          <div class="form-group">
            <label for="telefono">Telefono</label><br /><br />
            <input value={location.state.Telefono} className="form-control" type="text" id="telefono" disabled />
          </div>
        </div>
      </div>

      <div className="row">

        <div class="col-sm"> <div class="form-group">
          <label >Tipo</label><br /><br />
          <input value={location.state.Tipo} className="form-control" type="text" disabled />
        </div> </div>
        <div class="col-sm"><div class="form-group">
          <label >Posti</label><br /><br />
          <input value={location.state.Posti} className="form-control" type="text" disabled />
        </div></div>
        <div class="col-sm">
          <div class="form-group">
            <label >Data Prenotazione</label><br /><br />
            <input  id="giorno" defaultValue={location.state.Giorno} className="form-control" type="text" />
          </div>
        </div>

        <div class="col-sm">
          <div class="form-group">
            <label >Orario</label><br /><br />
            <input id="orario" defaultValue={location.state.Orario} className="form-control" type="text" />
          </div>
        </div>
      </div>

      <div className="row">

        <div class="col-sm"> <div class="form-group">
          <label >Totale</label><br /><br />
          <input value={location.state.Totale} className="form-control" type="text" disabled />
        </div> </div>
        <div class="col-sm"><div class="form-group">
          <label >ID transazione</label><br /><br />
          <input value={location.state.idTransazione} className="form-control" type="text" disabled />
        </div></div>
      </div>

      <div className="row" >

        <div class="form-group">
          <label for="note">Note</label>
          <textarea id="note" rows="3" ></textarea>
        </div>

        <div className="elementor-button-wrapper">
          <a style={{ float: 'right' }} className="elementor-button-link elementor-button elementor-size-sm" onClick={() => salva()} role="button">
            <span className="elementor-button-content-wrapper">
              <span className="elementor-button-icon elementor-align-icon-right">
              </span>
              <span className="elementor-button-text">Modifica</span>
            </span>
          </a>
          <a className="elementor-button-link elementor-button elementor-size-sm" href={'/#/prenotazioni'} role="button">
            <span className="elementor-button-content-wrapper">
              <span className="elementor-button-icon elementor-align-icon-right">
              </span>
              <span className="elementor-button-text">Indietro</span>
            </span>
          </a>

        </div>

      </div>

    </div>
  );
}
export default Modifica;
