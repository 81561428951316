import { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthUser from '../components/AuthUser';
import Domanda from '../components/domanda';
import Login from '../components/login';
import BOLayout from '../layout/BoLayout';
import MyAccount from './app-views/profilo/myaccount';
import NuovoMenu from './app-views/sviluppo.menu/nuovomenu';
import Permessi from './app-views/sviluppo.permessi/permessi';
import EditRole from './app-views/sviluppo.ruoli/editruolo';
import EditSubMenu from './app-views/sviluppo.submenu/editsubmenu';
import Dashboard from './Dashboard';
import Loading from '../components/shared-component/Loading'
import ContentExample from '../components/layout-component/ContentExample';
import Ipaddress from './app-views/sicurezza.ipaddresses/elencoipaddress';
import Menu from './app-views/sviluppo.menu/nuovomenu';
import Submenu from './app-views/sviluppo.submenu/elencosubmenu';
import Role from './app-views/sviluppo.ruoli/elencoruoli';
import Sede from './app-views/sviluppo.sede/nuovosede';
import Prodotti from '../components/prodotti';
import AppCall from '../components/shared-component/Fullcalendar';
import Analisi from './app-views/analisi';
import Prenotazioni from './app-views/prenotazioni';
import Modificaprenotazioni from './app-views/prenotazioni/modifica';
import Prenota from '../components/prenota';
import Prenotas from '../components/prenotas';
import Paga from '../components/paga';
import Pagas from '../components/pagas';
import Tavoli from '../components/tavoli';
import Tavolivip from '../components/tavoli/vip';
import Coupon from '../components/coupon';
import Gadget from '../components/gadget';
import Special from '../components/special';
import Stampe from '../components/stampe';
import Saltafila from '../components/saltafila';

const Views:React.FC = () => {
	const { localtoken, logout } = AuthUser();
	const logoutUser = () => {
		if (localtoken != null) {
			logout();
		}
	}

	useEffect(() => {
		const { getToken } = AuthUser();

	console.log('GetToken');

	}, []);

	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				
				<Route element={<BOLayout />}>
				<Route path="login" element={<Login />} />
					<Route index element={<ContentExample />} />
					<Route path="stampe" element={<Stampe />} />
					<Route path="tavoli" element={<Tavoli />} />
					<Route path="tavolivip" element={<Tavolivip />} />
					<Route path="prenotas" element={<Prenotas />} />
					<Route path="prenota" element={<Prenota />} />
					<Route path="gadget" element={<Gadget />} />
					<Route path="coupon" element={<Coupon />} />
					<Route path="saltafila" element={<Saltafila />} />
					<Route path="paga" element={<Paga />} />
					<Route path="pagas" element={<Pagas />} />
					<Route path="analisi" element={<Analisi />} />
					<Route path="specialprenotazione" element={<Special />} />
					<Route path="modificaprenotazione" element={<Modificaprenotazioni />} />
					<Route path="prenotazioni" element={<Prenotazioni />} />
					<Route path="dashboard" element={<ContentExample />} />
					<Route path="dashboard/prenota" element={<Prenota />} />
					<Route path="ipaddress" element={<Ipaddress />} />
					<Route path="menu" element={<Menu />} />
					<Route path="prodotti" element={<Prodotti />} />
					<Route path="permessi" element={<Permessi />} />
					<Route path="calendario" element={<AppCall />} />
					<Route path="submenu" element={<Submenu />} />
					<Route path="role" element={<Role />} />
					<Route path="sede" element={<Sede />} />
					<Route path="/profile/mioaccount" element={<MyAccount />} />
					<Route path="domanda" element={<Domanda />} />
					<Route path="/app/menu/create/:id" element={<NuovoMenu id={0} />} />
					<Route path="/app/submenu/create/:menuId/:id" element={<EditSubMenu id={0} menuId={0} />} />
					<Route path="/app/role/create/:id" element={<EditRole id={0} />} />
				</Route>
			</Routes>
		</Suspense>

	);
};

export default Views;