import React, { useState, useEffect } from "react";
import AuthUser from '../../../components/AuthUser';
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import "./DataTable.css";

function App() {
  const { http } = AuthUser();
  const navigate = useNavigate();
  const dataTemplate = async () => {

    const result = await http.get('/paypal');

    //console.log(result);
    const x = result.data.map((d, i) => {

      return {
        idTransazione: d.idtransazione,
        Giorno: d.giorno,
        Orario: d.orario,
        Posti: d.posti,
        Totale: d.amount,
        Pagamento: d.pagamento,
        Biglietto: d.biglietto,
        Nome: d.cambio_nome,
        Cognome: d.cambio_cognome,
        Telefono: d.cambio_telefono,
        Email: d.cambio_email,
        Status: d.status,
        Tipo: d.tipo,
        Data_Acquisto: d.datacreate,
      }
    }
    )
    return x;
  };

  const columns = [
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (<>

            <a className="bnt" onClick={() =>  navigate( '/modificaprenotazione' , { state: dataTemp[dataIndex] })} role="button">
  
                <span className="elementor-button-text">Modifica</span>
             
            </a>
            <br/>
            { dataTemp[dataIndex]['Biglietto'] &&
            <a className="bnt" href={'https://oktoberfestapi.guides4you.it/api/scaricaBiglietto?i='+dataTemp[dataIndex]['idTransazione']}  role="button">
  
  <span className="elementor-button-text">Biglietto</span>

</a>
        }

            
 </>
          );
        }
      }
    },

    {
      name: 'idTransazione',
      options: {
        filter: false,

      },
    },
    {
      name: 'Giorno',
      options: {
        filter: true,
      },
    },
    {
      label: 'Orario',
      name: 'Orario',
      options: {
        filter: false,
      },
    },
    {
      label: 'Posti',
      name: 'Posti',
      options: {
        filter: false,
      },
    },
    {
      label: 'Totale',
      name: 'Totale',
      options: {
        filter: false,
      },
    },
    {
      label: 'Biglietto',
      name: 'Biglietto',
      options: {
        filter: false,
      },
    },
    {
      label: 'Pagamento',
      name: 'Pagamento',
      options: {
        filter: true,
      },
    },
    {
      label: 'Nome',
      name: 'Nome',
      options: {
        filter: false,
      },
    },

    {
      label: 'Cognome',
      name: 'Cognome',
      options: {
        filter: false,
      },
    },
    {
      label: 'Telefono',
      name: 'Telefono',
      options: {
        filter: false,
      },
    },
    {
      label: 'Email',
      name: 'Email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Status',
      name: 'Status',
      options: {
        filter: true,
      },
    },
    {
      label: 'Tipo',
      name: 'Tipo',
      options: {
        filter: true,
      },
    },
    {
      label: 'Data Acquisto',
      name: 'Data_Acquisto',
      options: {
        filter: false,
      },
    }

  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    filterOptions: {
      names: ["Status",
        "Tipo", "Giorno", "Orario"]
    },
    textLabels: {
      body: {
        noMatch: "Nessuna transazione!"
      },

      selectedRows: {
        text: "rows has been deleted",
        delete: "Delete Row",
        deleteAria: "Deleted Selected Rows"
      }
    }
  };

  const [dataTemp, setDataTemp] = useState(null);

  useEffect(() => {
    (async () => {
      let result = await dataTemplate();
      console.log('REsult', result);
      setDataTemp(result);
    })()

  }, []);


  return (
    <div>
      {dataTemp &&
        <div style={{
          overflow: 'auto', width: 1200,
          margin: '0 auto',
          whiteSpace: 'nowrap'
        }}>
          <MUIDataTable
            title={"Prenotazioni"}
            data={dataTemp}
            columns={columns}
            options={options}
          />
        </div>
      }
    </div>
  );
}
export default App;
