import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import MUIDataTable from "mui-datatables";


export default function Coupon() {


    const { http } = AuthUser();
    const [coupon, setcoupon] = useState([]);

    const columns = ["Numero", "Nome", "Codice", "Tiposconto", "Valore", 'Datavalidita'];

    const options = {
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div>
                <button onClick={() => console.log(selectedRows)}>Elimina Coupon </button>

            </div>
        ),
        filter: true,
        filterType: "dropdown",
        responsive: "simple",
        textLabels: {
            body: {
                noMatch: "Nessuna transazione!"
            },
            pagination: {
                next: "Prossima Pag.",
                previous: "Pag. Precede te",
                rowsPerPage: "Righi per pag:",
                displayRows: "di",
            },
            filter: {
                all: "Tutti i Record",
                title: "OUR FILTERS",
                reset: "PERFORM RESET"
            },
            selectedRows: {
                text: "Elimina i coupon selezionati?",
                delete: "Elimina Riga",
                deleteAria: "Elimina riga selezionata"
            }
        }
    };


    const [states, setstates] = useState({
        numero: 1,
        nome: null,
        codice: null,
        tiposconto:  null,
        valore: null,
        datavalidita: null
    });

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    useEffect(() => {
        (async () => {
            let result = await dataTemplate();
            setcoupon(result);
        })()

    }, []);

    const dataTemplate = async () => {

        const result = await http.get('/coupon');

        const x = result.data.map((d, i) => {

            return {
                Numero: d.id,
                Nome: d.nome,
                Codice: d.codice,
                Tiposconto: d.tiposconto,
                Valore: d.valore,
                Datavalidita: d.datavalidita
            }
        }
        )
        return x;
    };


    const handleInputChange = (evt) => {

        const s = states;

        s[evt.target.id] = evt.target.value;
        setstates(s);

    }

    const savecouponDetail = () => {

        http.post('coupon', states).then((response) => {
            if (response.data.success) {
                Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });
                window.$('.bd-example-modal-lg').modal('toggle');

            }
        }).catch((reason) => {

            Toast.fire({
                title: reason.response.data.message,
                icon: 'error'
            });
        })

    }

    return (
        <div>
            <h1 className='mb-4 mt-4'>coupon</h1>
            <div>
                <div className="row">
                    <button
                        type="button"
                        className="btn btn-primary "
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg">
                        Crea Coupon

                    </button>
                </div>
                <div className="row">

                    <div className="main">
                        {coupon &&
                            <MUIDataTable
                                title={"coupon"}
                                data={coupon}
                                columns={columns}
                                options={options}
                            />
                        }
                    </div>

                    {/*coupon != null &&
                        coupon.map((t) =>
                            <div style={{ float: 'left', maxWidth: 280, margin: 20 }} className="card-wrapper">
                                <a className="card card-img no-after special-card" href="#">

                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {t.tipo}
                                        </h5>
                                        <h5 className="card-title">
                                            {t.posti}
                                        </h5>

                                        <h5 className="card-title">
                                            {t.nome}
                                        </h5>
                                    </div>

                                </a>
                            </div>

                        )
                    //*/}
                </div>


                <div
                    className="modal fade bd-example-modal-lg"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content" style={{ padding: 30 }} >
                            <h5
                                className="modal-title"
                                id="myLargeModalLabel" style={{ margin: 20 }}>Crea Coupon </h5>
                            <div className="form-group">
                                <label htmlFor="nome">Nome</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nome"
                                    name="nome"
                                    defaultValue={states.nome} onChange={handleInputChange}
                                />
                            </div>
                            <div className="select-wrapper">
                                <label for="tiposconto">Tipo Coupon</label>
                                <select id="tiposconto" name="tiposconto" defaultValue={states.tiposconto} onChange={handleInputChange}>
                                    <option selected="" value="">Scegli un'opzione</option>
                                    <option value="Percentuale">Percentuale</option>
                                    <option value="Valore">Valore</option>
                                </select>
                            </div>

                            <br />

                            <div className="row">
                                <div class="w-100">
                                    <label for="numero" class="input-number-label">Numero di Coupon da Abilitare </label>
                                    <span class="input-number">
                                        <input type="number" id="numero" name="numero" defaultValue={states.numero} onChange={handleInputChange} />
                                        <button class="input-number-add">
                                            <span class="sr-only">Aumenta valore</span>
                                        </button>
                                        <button class="input-number-sub">
                                            <span class="sr-only">Diminuisci valore</span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div class="w-100">
                                    <label for="valore" class="input-number-label">Valore Coupon</label>
                                    <span class="input-number">
                                        <input type="number" id="valore" defaultValue={states.valore} onChange={handleInputChange} />
                                        <button class="input-number-add">
                                            <span class="sr-only">Aumenta valore</span>
                                        </button>
                                        <button class="input-number-sub">
                                            <span class="sr-only">Diminuisci valore</span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div class="w-100">
                                    <label for="codice" class="input-number-label">Codice Coupon</label>
                                    <span class="input-group">
                                        <input type="text" id="codice" defaultValue={states.codice} onChange={handleInputChange} />

                                    </span>
                                </div>
                            </div>
                            <br />

                            <div className="col-sm">  <div className="form-group">
                                <label className="active" for="datavalidita">Data Fine Validita</label>
                                <input
                                    type="date"
                                    id="datavalidita"
                                    name="datavalidita"
                                    defaultValue={states.datavalidita}
                                    onChange={handleInputChange}
                                />

                            </div> </div>

                            <button
                                onClick={savecouponDetail}
                                type="button"
                                className="btn btn-primary "
                                data-target=".bd-example-modal-lg">
                                Salva
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}