import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';


export type UserState = {
    isLogged: boolean,
    pagamento:any,
    showHF:boolean
   
    }
const initialState : UserState = {
    isLogged: false,
    pagamento:null,
    showHF:false

}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsLogged: (state:any, action: PayloadAction<boolean>)=>{
            state.isLogged=action.payload
        },
        setPagamento:(state:any, action: PayloadAction<any>)=>{
            state.pagamento=action.payload
        },
        setshowHF:(state:any, action: PayloadAction<any>)=>{
            state.showHF=action.payload
        },
    }
});

export const {setIsLogged,setPagamento,setshowHF} = userSlice.actions;
export const selectUser = (state: RootState) => state.user
export default userSlice.reducer;
