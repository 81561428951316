import React, { useState, useEffect } from 'react';
import AuthUser from '../AuthUser';
import { Link } from 'react-router-dom';
const Aside = (props) => {

    const http = AuthUser().http;

    const [username, setUsername] = useState(null);
    const [activeMenu, setActiveMenu] = useState({});
    const [menustructure, setMenustructure] = useState([]);



    useEffect(() => {
        http.get('/menurender', {}).then(response => {

            setMenustructure(response.data.menu);
            setUsername(response.data.userdata);

        });

    }, [])

    const activateMenu = ({ menuid, submenuid }) => {
        setActiveMenu({ menuid: menuid, subid: submenuid });

    }

    return (
        <ul class="navbar-nav">
            {/* Sidebar */}

            {/* Sidebar Menu */}

            {
                menustructure.map((m, i) => {

                    if (m.menu.has_submenus == 1) {
                        return (

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="true" id={`menu${m.menu.id}`} data-focus-mouse="false">
                                    <span>{m.menu.name}</span>
                                    <svg class="icon icon-xs">
                                        <use href="/bootstrap-italia/dist/svg/sprite.svg#it-expand"></use>
                                    </svg>
                                </a>
                                <div class="dropdown-menu" role="region" aria-labelledby={`menu${m.menu.id}`}>
                                    <div class="link-list-wrapper">

                                        <ul class="link-list">

                                            {m.submenu.map((s, ii) => {

                                                return (
                                                    <li><Link className="list-item" to={s.function}><span>{s.name}</span></Link>
                                                    </li>

                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        );
                    } else {

                        return (
                            <li><Link className="list-item" to={m.submenu[0].function}><span>{m.menu.name}</span></Link>
                            </li>

                        );
                    }
                })
            }


            {/* /.sidebar-menu */}

        </ul>
    )
}


export default Aside;

