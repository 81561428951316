import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import { Link } from 'react-router-dom';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import IconSelector from '../../../components/shared-component/IconSelector';
import Swal from 'sweetalert2';
const links = [
    { url: '/', name: 'Home', active: false },
    { url: '/role', name: 'Ruoli', active: false },
    { url: '/role/editruolo', name: 'Crea/aggiorna Ruolo', active: true },
];

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const EditRole = (props) => {
    const http = AuthUser().http;

   const  [states,setstates]= useState({
    insertNew: !props.id,
    id: 0,
    name: null,
    ruolo: '',
    active: 1,
});

   
    useEffect(()=> {
        if (!states.insertNew) {
            http.get(`role/${props.id}/edit`).then((response) => {
                setstates({ ...response.data })
            })
        }
          },[]);

  
 const   handleSave = () => {
        if (states.insertNew) {
            http.post('role', { ...states }).then((response) => {
                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                }
            })
        } else {
            http.put(`role/${states.id}`, states).then((response) => {
                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                }
            })
        }
    }
  
        return (
            <>
                <div>
                    <HeaderContent title={states.insertNew ? 'Nuovo Ruolo' : 'Edit ruolo'} subtitle="definizione dei ruoli di accesso" links={links} />

                    {/* Main content */}
                    <section className="content">
                        <div className="row justify-content-md-center m-0">
                            <div className="col-md-12">
                                {/* general form elements */}
                                <div className="card card-primary">
                             
                                    <div className="card-body">
                                        <div className="col-lg-offset-3 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Nome</label>
                                                <input type="text" className="form-control" id="name" name="name"  defaultValue={states.name} onChange={(evt) => states.name= evt.target.value} />
                                            </div>
                                       
                                        <div className="form-group">
                                            <label htmlFor="ruolo">Ruolo</label>
                                            <input type="text" className="form-control" id="ruolo" name="ruolo"  defaultValue={states.ruolo} onChange={(evt) =>  states.ruolo = evt.target.value }/>
                                        </div>
                                    </div>
                                   
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary" onClick={handleSave}>Salva</button>
                                            <Link to="/role" className="btn btn-warning ml-2">Indietro</Link>    
                                        </div>
                                    
                                </div>
                            </div>
                            {/* /.box */}
                        </div>
                        {/* /.col*/}
                      </div>
                    </section>


            </div>



            </>

        )
    }
export default EditRole ;
