import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import { Link } from 'react-router-dom';
import Accordion from '../../../components/shared-component/Accordion';
import Swal from 'sweetalert2';

const links = [
    { url: '/', name: 'Home', active: false },
    { url: '/menu', name: 'Menù', active: false },
    { url: '/menu/nuovomenu', name: 'Nuovo Menù', active: true },
];

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
});


const ElencoSubMenu =() =>  {
    const http = AuthUser().http;
    const  [menus,setmenus]= useState([]);

    useEffect(()=> {
        http.get(`menu`, {}).then(response => {
           
            setmenus(response.data );
                     
        });

    },[])

 
   const itemDelete = (id) => {
        http.delete(`submenu/${id}`).then((response) => {
            if (response.data.success) {
                Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });

                setmenus(response.data.menus );
                
            }
        })
    }



        return (
            <div>
                <HeaderContent title="Funzioni di Menù" subtitle="crea e modifica le funzioni dei sottomenù" links={links} />



                <div>

                    {/* Main content */}
                    <section className="content">
                        <div className="row m-3">
                            {/* Default box */}
                            <div className="col-md-12">
                                <div className="card card-solid">
                                    <div className="card-header with-border">
                                        <h3 className="card-title">Aggiungi Nuove Funzioni</h3>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="card-body">
                                        <div className="box-group" id="accordion">
                                            {menus.map((m, i) => {



                                                return (
                                                    <div key={i}>
                                                        <Accordion name={m.name}>
                                                            {m.has_submenus == 1 &&
                                                                <Link to={`/app/submenu/create/${m.id}/0`} className="col-lg-offset-5 btn btn-success mb-3">Nuova Funzione/Sottomenù</Link>}
                                                            {m.has_submenus == 0 && m.sub.length == 0 &&
                                                                <Link to={`/app/submenu/create/${m.id}/0`} className="col-lg-offset-5 btn btn-success mb-3">Nuova Funzione/Sottomenù</Link>}

                                                            <table className="table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Id Sottomenu</th>
                                                                        <th>Nome Sottomenu</th>
                                                                        <th>Funzione</th>
                                                                        <th>Modifica</th>
                                                                        <th>Elimina</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {m.sub.map((s, ii) => {
                                                                        return (
                                                                            <tr key={ii}>
                                                                                <td>{s.id}</td>
                                                                                <td>{s.name}</td>
                                                                                <td>{s.function}</td>
                                                                                <td><Link to={`/submenu/edit/${s.id}`}><i className="fas fa-edit" /></Link></td>
                                                                                <td><a href="#" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    Swal.fire({
                                                                                        title: 'Vuoi eliminare la funzione ?',
                                                                                        showDenyButton: false,
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: `OK`,

                                                                                    }).then((result) => {
                                                                                        /* Read more about isConfirmed, isDenied below */
                                                                                        if (result.isConfirmed) {
                                                                                            itemDelete(s.id);
                                                                                        }
                                                                                    })

                                                                                }}><i className="fas fa-trash" /></a></td>
                                                                            </tr>
                                                                        )
                                                                    })}

                                                                </tbody>
                                                            </table>


                                                        </Accordion>

                                                    </div>
                                                );
                                            })}


                                        </div>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                                {/* /.box */}
                            </div>
                        </div>
                    </section>
                    {/* /.content */}
                </div>
            </div>
        )
    
}

export default ElencoSubMenu;