import axios from 'axios';
import { API_URL_LARAVEL } from '../config/config';

export default function AuthUser(){


    const getToken = () =>{
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }
    
    let localtoken = null;
    let localuser = null;
  //  const [token,setToken] = useState(getToken());
  //  const [user,setUser] = useState(getUser());

    const saveToken = (user,token) =>{
        sessionStorage.setItem('token',JSON.stringify(token));
        sessionStorage.setItem('user',JSON.stringify(user));

        localtoken = token;
        localuser = user;
     

       // navigate('/dashboard');
    }

    const logout = () => {
        sessionStorage.clear();
        window.location.href = "/#/login";
    }

    const http = axios.create({
        baseURL:API_URL_LARAVEL,
        headers:{
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${getToken()}`
        }
    });

    http.interceptors.response.use((response) => response, (error) => {
        if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/#/login";
          //  navigate('/login');

        }
      });
      

    return {
        setToken:saveToken,
        localtoken,
        localuser,
        getToken,
        http,
        logout
    }
}