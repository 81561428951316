
import React, { useState, useEffect } from 'react';
import NavOrizBar from './NavOrizBar'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectUser, setIsLogged } from '../../features/user/userSlice';
import AuthUser from '../AuthUser';
import { useDispatch } from 'react-redux';
import { width } from '@mui/system';


const Header: React.FC = (  ) => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();
    return (
      <>
  {/**  <header className="it-header-wrapper it-header-sticky" style={{flexShrink: 0}}>
  */} 
  
   <header  style={{flexShrink: 0, width: user.isLogged?'100%':'auto'}}>

    
   
 { user.isLogged && user.showHF &&
  <div className="it-nav-wrapper">
    
    <div className="it-header-navbar-wrapper theme-light-desk">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg has-megamenu">
              <button
                aria-controls="nav10"
                aria-expanded="false"
                aria-label="Toggle navigation"
                className="custom-navbar-toggler"
                data-target="#nav10"
                type="button"
              >
                <svg className="icon">
                  <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-burger"></use>
                </svg>
              </button>
           <NavOrizBar/>

           { !user.isLogged &&
              <a className="btn btn-primary btn-icon btn-full" href="/#/login" title="Accedi all'area personale" aria-label="Accedi all'area personale">
                <span className="rounded-icon">
                  <svg className="icon icon-primary">
                    <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-user"></use>
                  </svg>
                </span>
                <span className="d-none d-lg-block">Accedi all'area personale</span>
              </a>
}  {
  user.isLogged &&
  <button className="btn btn-primary btn-icon btn-full" onClick={()=>{ 

    AuthUser().logout();
    dispatch(setIsLogged(false));
    navigate('/login');

  }} 
  title="logout" aria-label="Logout">
    <span className="d-none d-lg-block">Logout</span>
  </button>
}
            </nav>
            
          </div>
        </div>
      </div>
    </div>
    
  </div>
}
</header>
      </>

    );
  
}

export default Header;
