import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



export default function Prenota() {

    const navigate = useNavigate();
    const location = useLocation();

    const [prezzo, setprezzo] = useState(16);
    const { http } = AuthUser();
    const [associata, setassociata] = useState(false);
    const [fasciaorarioa, setfascia] = useState('20:00');
    const [states, setstates] = useState([]);
    const [prenotati, setprenotati] = useState(1);
    const [tipo, settipo] = useState('salta');
  
 
    const [prenotazioneusata, setprenotazioneusata] = useState(null);
    const [prenotazione, setprenotazione] = useState(null);

    const [totale, settotale] = useState(16);
    useEffect(() => {

    }, [totale]);


    useEffect(() => {
        let tot = Math.floor(prenotati * prezzo);

        settotale(tot);
    }, [prezzo]);

    const reset = (evt) => {
        setassociata(false);
        setfascia('');
        setstates([]);
        settipo('');
    
        setprenotazioneusata(null);
        setprenotazione(null)
        settotale(0);
        setprenotati(0);

    }



    const modificaPostimeno = (evt) => {

        let p = prenotati;

        p--;
        if (p < 0) {
            prenotati = 0;
        } else {
            let tot = totale;
            tot -= Math.floor(prezzo)
            settotale(tot);
        }

        setprenotati(p);
    }
    const modificaPostipiu = (evt) => {

        let p = prenotati;

        p++;
        let tot = totale;
        tot += Math.floor(prezzo)
        settotale(tot);
        setprenotati(p);
    }

    const handleInputChangepre = (evt) => {
        //  console.log(evt);
        setprenotazione(evt.target.value);

    }
    const handleKeyPress = (evt) => {
        //  console.log(evt);
    }

    const setCoupon = () => {

        let text = "OKTRNTL3";
         let result = text.toUpperCase();
         let val = document.getElementById("coupon").value;
         let vals=val.toUpperCase();

        if (result==vals) {
            setprezzo(13);

            Toast.fire({
                title: 'Coupon accettato',
                icon: 'success'
            });
        } else {
            Toast.fire({
                title: 'Codice Coupon errato',
                icon: 'error'
            });
            setprezzo(16);
        }
    }
    const handleInputChangefascia = (evt) => {
        setfascia(evt.target.value);
    }

    const Toast = Swal.mixin({
        position: 'top-end',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
    })

    return (
        <div
        >
            <div className="elementor-widget-container">
                <h2 style={{ fontWeight: 'bold', textAlign: 'center' }} className="elementor-heading-title elementor-size-default">Prenotazione per giorno {moment(location.state.dataprenotazione).format('DD-MM-YYYY')}</h2>
            </div>
            <span className="elementor-divider-separator">
                <img className="separw" style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }} src="/separatore.png"></img>
            </span>

            <div>
                { /*<div className="row">
                    <div className="form-group">
                        <label htmlFor="posti" className="input-number-label">Numero di Posti Da Prenotare</label>
                       
                            <input type="number" id="posti"  aria-describedby="postidesc" name="posti" defaultValue={postiprenotati.posti} onChange={modificaPosti} />
                            <small id="postidesc" className="form-text text-muted">Numero di posti al tavolo</small>     
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <label htmlFor="coupon" className="input-number-label">Coupon</label>
                      
                            <input type="text" id="coupon"  aria-describedby="coupondesc" name="coupon" onChange={modificaPosti} />

                            <small id="coupondesc" className="form-text text-muted">Inserisci un coupon sconto</small>
                    </div>
                </div>
                <div className="row">
                <div className="form-group">
                        <label htmlFor="prenotazione" className="input-number-label">Associa Prenotazione</label>
                       
                            <input type="text" id="prenotazione" aria-describedby="prenotdesc"  name="prenotazione" onChange={modificaPosti} />

                            <small id="prenotdesc" className="form-text text-muted">Associa la prenotazione di un'amico per stare assieme al tavolo</small>                    
                    </div>
                </div>

                <br/>
    */}





                {fasciaorarioa != '' && <div>
                    <div style={{width:'100%'}} key='saltaf10' className="row prod" >

                        <div style={{ float: 'left', maxWidth: 280, margin: 20 }} className="col-sm card-wrapper">
                            <div style={{ border: 0 }} className="card  no-after special-card" href="#">
                                <div className="img-responsive-wrapper">
                                    <div className="img-responsive">
                                        <figure className="img-wrapper"> <img
                                            src={'saltafila.jpeg'}
                                            title="Saltafila"
                                            alt="imagealt" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: 20 }} className='col-sm'>
                            <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }}>Salta Fila</label>
                            <br />
                            <br />

                            <p style={{ margin: 0 }}>Ingresso Palatenda privilegiato</p>

                            <br />
                            <p style={{ margin: 0 }}>Boccale di birra OMAGGIO</p>

                                <br />

                            <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} >Prezzo</label>
                            <label style={{ marginLeft: 5 }}>{parseFloat(prezzo).toFixed(2) + ' €'}</label>
                        </div>

                        <div style={{ display:'none', padding: 15 }} className='col-sm align-self-center'>
                            <div className="mobs50 w-50 mt-5">
                                <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 15 }} htmlFor={'posti-salta'} className="input-number-label">Numero </label>
                                <span className="input-number">
                                    <input type="number" id={'posti-salta'} value={prenotati} name={'posti-salta'} data-bs-input step="any" />
                                   
                                </span>
                            </div>

                        </div>

                    </div>

                    <div className='separt'> </div>

                    {totale > 0 && <div style={{padding:20}} className="row ">
                        <div className='row' >
                            <div className='col-sm' >
                               
                                    <label style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} >Hai un codice sconto?</label>

                                    <input type="text" id="coupon" aria-describedby="coupondesc" placeholder="Inseriscilo qui..."  name="coupon" />
                                    <div className="elementor-button-wrapper">
                                  <br/>
                                    <a className="elementor-button-link elementor-button elementor-size-sm" onClick={() =>setCoupon()} role="button">
                                        <span className="elementor-button-content-wrapper">
                                            <span className="elementor-button-icon elementor-align-icon-right">
                                            </span>
                                            <span className="elementor-button-text">Applica</span>
                                        </span>
                                    </a>
                                    </div>

                            </div>
                            <div className='col-sm' >
                                 </div>
                            <div className='prex col-sm'>
                            <label  style={{float:'right', fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold', fontSize: 20 }} >Prezzo Totale {totale + ' €'}</label>
                               

                                <div className="elementor-button-wrapper">
                                    <br/> <br/> <br/> 
                                    <a style={{float:'right'}} className="elementor-button-link elementor-button elementor-size-sm" onClick={() => totale > 0 && navigate('/pagas', { state: {   prenotati: prenotati, totale: totale,  tipo: tipo, fasciaoraria: fasciaorarioa, dataprenotazione: moment(location.state.dataprenotazione).format('YYYY-MM-DD') } })} role="button">
                                        <span className="elementor-button-content-wrapper">
                                            <span className="elementor-button-icon elementor-align-icon-right">
                                            </span>
                                            <span className="elementor-button-text">Avanti</span>
                                        </span>
                                    </a>
                                </div>

                            </div>
                        </div>


                    </div>
                    }
                </div>}
            </div>
            <script>
            </script>
        </div>
    )
}